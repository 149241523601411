<template>
  <div>
    <section class="section is-medium pt-3" id="agb">
      <div class="container">
        <router-link class="button" :to="'/' + $route.params.locale + '/'">{{
          $t("views.returned.back")
        }}</router-link>
        <div class="content">
          <div class="columns mt-6">
            <div class="column is-narrow is-offset-1">
              <div
                class="is-flex is-justify-content-center is-align-items-center has-text-primary mx-auto mt-5"
                style="
                  border-radius: 999px;
                  background: #fadace;
                  height: 200px;
                  width: 200px;
                "
              >
                <span>
                  <fa class="fa-fw fa-5x" icon="check" />
                </span>
              </div>
            </div>
            <div class="column is-offset-1">
              <h1 class="is-size-3 has-text-grey">
                {{ $t("views.returned.headline") }}
              </h1>
              <h4 class="mb-1 mt-6">
                {{ $t("views.registered.next-steps.headline") }}
              </h4>
              <p v-if="email" class="has-text-grey">
                <i18n
                  path="views.returned.next-steps.description.text-personalized"
                >
                  <strong>{{ email }}</strong>
                  <a href="mailto:repguide@fricke.de">repguide@fricke.de</a>
                </i18n>
              </p>
              <p v-else class="has-text-grey">
                <i18n path="views.returned.next-steps.description.text">
                  <a href="mailto:repguide@fricke.de">repguide@fricke.de</a>
                </i18n>
              </p>
              <h4 class="mb-1 mt-6">
                {{ $t("views.registered.first-steps.headline") }}
              </h4>
              <p class="has-text-grey">
                {{ $t("views.registered.first-steps.description.text") }}
              </p>
              <video
                controls
                preload="metadata"
                width="1000"
                :poster="
                  $root.$i18n.locale === 'de'
                    ? 'https://cdn.rep.guide/app-files/screencasts/repguide-screencast-welcome-20210924.jpg'
                    : 'https://cdn.rep.guide/app-files/screencasts/repguide-screencast-welcome-20210924-en.png'
                "
              >
                <source
                  src="https://cdn.rep.guide/app-files/screencasts/repguide-screencast-welcome-20210924.mp4"
                  type="video/mp4"
                />
                <track
                  kind="subtitles"
                  src="/repguide-screencast-welcome-20210924.vtt"
                  srclang="en"
                  label="English"
                  :default="$root.$i18n.locale !== 'de'"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footerNavigation />
  </div>
</template>

<script>
import footerNavigation from "../components/footerNavigation.vue";

export default {
  name: "returned-page",
  components: {
    footerNavigation,
  },
  computed: {
    email() {
      return this.$route.query?.email || "";
    },
  },
};
</script>

<style lang="scss">
.serviceCheckbox {
  width: 50px;
}
.is-custom-for-bulma {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1216px) {
    grid-template-columns: 1fr;
    div:nth-of-type(1) {
      grid-row: 3;
    }
    div:nth-of-type(3) {
      grid-row: 4;
    }
  }
}
</style>
