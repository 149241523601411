<template>
  <section class="is-relative">
    <figure class="image" style="pointer-events: none;">
      <img
        src="../assets/mockup/_device-laptop.png"
        style="filter: drop-shadow(rgba(0,0,0,.2) 2px 5px 5px);"
      />
    </figure>
    <div class="mock-scroll-container">
      <figure class="image">
        <img
          v-if="this.$root.$i18n.locale === 'de'"
          src="../assets/mockup/preview-laptop-DE.jpeg"
        />
        <img v-else src="../assets/mockup/preview-laptop-EN.jpeg" />
      </figure>
    </div>
    <div class="mock-scroll-shadow" />
  </section>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      const CONTAINERS = document.querySelectorAll(".mock-scroll-container");

      CONTAINERS?.forEach(CONTAINER => {
        CONTAINER.scroll({
          top: 100,
          behavior: 'smooth'
        });
      })
    }, 1000)
  },
}
</script>

<style lang="scss">
.mock-scroll-container {
  position: absolute;
  inset: 6.5% 12% 9.5%;
  overflow-y: scroll;
  img {
    pointer-events: none;
    user-select: none;
  }
}
.mock-scroll-shadow {
  position: absolute;
  inset: 6.5% 12% 9.5%;
  box-shadow: inset 0px 1px 4px #0003;
  border: 1px solid #0003;
  pointer-events: none;
  z-index: 1;
}
</style>