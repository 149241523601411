<template>
  <div id="page-education">
    <navigation fancy :utm="utm" />

    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-multiline is-mobile is-vcentered py-6 my-6">
            <div
              class="
                column column-voodoo
                is-10-tablet is-offset-1-tablet is-5-desktop is-5-fullhd
              "
            >
              <div class="card is-shadowless has-background-light">
                <div class="card-content">
                  <h2 class="title is-marginless is-size-3 is-size-4-mobile">
                    {{ $t("views.education.hero.headline") }}
                  </h2>
                </div>
              </div>
              <a
                href="#educationform"
                class="
                  hero-cta
                  is-block
                  py-3
                  px-5
                  has-background-primary has-text-white
                  title
                  is-4
                "
              >
                {{ $t("views.education.hero.cta") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <a
        href="#educationform"
        class="hero-scroller has-text-white has-text-centered"
      >
        <fa icon="angle-double-down" class="fa-2x" />
      </a>
    </section>

    <section class="section is-medium" id="educationform">
      <div class="container pb-6">
        <h2 class="title is-size-2 is-size-4-mobile text-dark-600">
          {{ $t("views.education.intro.headline") }}
        </h2>
        <p class="mb-5">
          {{ $t("views.education.intro.description") }}
        </p>
        <ul class="has-text-weight-bold has-repguide-bullets">
          <li class="mt-4">{{ $t("views.education.intro.bullet-1") }}</li>
          <li class="mt-4">{{ $t("views.education.intro.bullet-2") }}</li>
          <li class="mt-4">{{ $t("views.education.intro.bullet-3") }}</li>
        </ul>
      </div>
      <div class="container">
        <h2 class="title is-size-3 is-size-5-mobile text-dark-600">
          {{ $t("views.education.howto.headline") }}
        </h2>
        <div class="timeline">
          <div class="is-relative is-clipped">
            <strong class="timeline-marker">1</strong>
            <span class="timeline-rule" />
          </div>
          <div class="pb-5">
            <h4
              class="mt-1 mb-3 title is-size-4 is-size-5-mobile text-dark-600"
            >
              {{ $t("views.education.howto.step-1.headline") }}
            </h4>
            <p>
              <a
                href="/files/RepGuide-User-Agreement-Educational-Institutions-DE.pdf"
                target="_blank"
              >
                <fa icon="file-pdf" />
                <span class="is-underlined ml-1">{{
                  $t("views.education.howto.step-1.form-download-de")
                }}</span>
              </a>
              <br />
              <a
                href="/files/RepGuide-User-Agreement-Educational-Institutions-EN.pdf"
                target="_blank"
              >
                <fa icon="file-pdf" />
                <span class="is-underlined ml-1">{{
                  $t("views.education.howto.step-1.form-download-en")
                }}</span>
              </a>
            </p>
          </div>
          <div class="is-relative is-clipped">
            <strong class="timeline-marker">2</strong>
            <span class="timeline-rule" />
          </div>
          <div class="pb-5">
            <h4
              class="mt-1 mb-3 title is-size-4 is-size-5-mobile text-dark-600"
            >
              {{ $t("views.education.howto.step-2.headline") }}
            </h4>
            <address>
              Fricke FRI:X Innovationlab<br />
              Spaldingstraße 68<br />
              20097 Hamburg
            </address>
            <article class="message mt-4 is-inline-block">
              <div class="message-body">
                {{ $t("views.education.howto.step-2.address-info") }}
              </div>
            </article>
          </div>
          <div class="is-relative is-clipped">
            <strong class="timeline-marker">3</strong>
          </div>
          <div class="pb-5">
            <h4
              class="mt-1 mb-3 title is-size-4 is-size-5-mobile text-dark-600"
            >
              {{ $t("views.education.howto.step-3.headline") }}
            </h4>
          </div>
        </div>
      </div>
    </section>

    <quote />

    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import quote from "../components/sections/quote_new.vue";
import footerNavigation from "../components/footerNavigation.vue";
import { generateSearchParams } from "../utm.js";

export default {
  name: "education",
  props: {
    utm: { type: Object, default: () => {} },
  },
  computed: {
    utmString() {
      const UTM = { ...this.utm, utm_content: "landingpage_education" };
      return generateSearchParams(UTM);
    },
  },
  components: {
    navigation,
    quote,
    footerNavigation,
  },
};
</script>

<style lang="scss">
.timeline {
  display: grid;
  grid-template-columns: 36px auto;
  gap: 4px 18px;
  .timeline-marker {
    background: #e94a10;
    border-radius: 999px;
    width: 36px;
    height: 36px;
    display: grid;
    place-content: center;
    color: white;
    font-size: 1.25rem;
    font-weight: 800;
  }
  .timeline-rule {
    position: absolute;
    top: 40px;
    left: calc(50% - 2px);
    background: lightgrey;
    width: 4px;
    height: 100%;
  }
}
ul.has-repguide-bullets {
  li {
    padding-left: 2.7rem;
    line-height: 1.7rem;
    position: relative;
  }
  li:before {
    content: "";
    display: block;
    position: absolute;
    height: 24px;
    width: 16px;
    background: #e94a10;
    top: 0.1rem;
    left: 0.75rem;
    transform: skewX(-12.56deg);
  }
}
#page-construction .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../assets/repguide-background-construction.jpg") no-repeat center
      bottom fixed;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  #savetime.section,
  #screenshots.section {
    padding: 1.5rem 0.5rem;
  }

  #savetime .card .card-content {
    padding: 1rem;
  }
}
</style>
