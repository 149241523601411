<template>
  <section
    v-if="showCounseling"
    class="section is-medium desktop-pull py-6 mb-6"
    id="counseling"
  >
    <div class="container">
      <div class="columns is-mobile is-multiline">
        <div
          class="column is-full-mobile is-4-tablet is-4-desktop is-offset-1-widescreen is-relative columns is-mobile image-positioning"
        >
          <div
            id="image-jan"
            class="column is-7-mobile is-four-fifths-tablet is-offset-5-mobile"
          >
            <figure class="image is-square">
              <img
                :alt="$t('views.index.about.money.mood-alt')"
                class="is-rounded"
                src="../../assets/counseling-banner-jan.webp"
                style="border: 8px solid white"
              />
            </figure>
          </div>
        </div>
        <div
          class="column is-full-mobile is-8-tablet is-6-widescreen is-flex is-align-items-center mt-6"
        >
          <div
            class="card card-counseling has-background-primary is-shadowless"
          >
            <span class="card-bubble-left-single" />
            <!-- NOTE: hardcoded German literals are expected here -->
            <!-- NOTE: adding keys to our i18n is on hold as of June 29th -->
            <!-- NOTE: besides the component will only ever be displayed in German -->
            <div class="card-content">
              <p class="title has-text-primary-light mt-0 mb-5 is-size-3">
                Noch unsicher?
              </p>
              <p class="subtitle has-text-primary-light is-size-5">
                Buchen Sie sich ein Beratungsgespräch mit unserem Kundenberater
                Jan
              </p>
              <div class="content has-text-primary-light has-text-right">
                <a
                  href="https://airtable.com/shr5uimerXqs75VJa"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="button is-medium is-white is-size-5-mobile"
                >
                  Zur Terminbuchung
                  <fa class="ml-2 fa-fw" icon="external-link-alt" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      TOP_LEVEL_DOMAIN: (
        window.location.hostname.match(/\.co\.uk/)?.[0] ||
        window.location.hostname.split(".").pop()
      ).toLowerCase(),
    };
  },
  computed: {
    showCounseling() {
      // NOTE: using tld instead of store/selectedPartner because it is much faster
      return (
        ["de", "at", "localhost"].includes(this.TOP_LEVEL_DOMAIN) &&
        this.$root.$i18n.locale === "de"
      );
    },
  },
};
</script>

<style lang="scss">
.card-bubble-left-single {
  position: absolute;
  top: -40px;
  left: 45%;
  border: 20px solid #e94a10;
  border-top-color: transparent;
  border-left-color: transparent;
}

@media (min-width: 769px) {
  .card-bubble-left-single {
    top: 80px;
    left: -40px;
    border: 20px solid #e94a10;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

// @media (min-width: 1024px) {
//   .desktop-pull {
//     margin: -175px 0 -200px;
//   }
// }

// @media (min-width: 769px) {
//   .image-positioning {
//     #image-jan {
//       position: absolute;
//       left: 8px;
//     }
//   }
// }

// @media (max-width: 768.999px) {
//   .image-positioning {
//     #image-jan {
//       margin-bottom: -11vw;
//     }
//   }
// }
</style>
