<template>
  <nav
    :class="[
      'navbar',
      'is-spaced',
      'is-fixed-top',
      { 'is-transparent': navtransparent },
    ]"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="container">
      <div class="navbar-brand">
        <router-link :to="'/' + $route.params.locale + '/'">
          <img
            v-if="navtransparent"
            alt="Rep.Guide Logo White"
            src="../assets/RepGuideLogoWhite.svg"
            width="160"
          />
          <img
            v-else
            alt="Rep.Guide Logo"
            src="../assets/RepGuideLogo.svg"
            width="160"
          />
        </router-link>

        <div class="navbar-item is-hidden-desktop ml-auto">
          <language-select />
        </div>

        <a
          role="button"
          :class="[
            'navbar-burger burger',
            'is-hidden-desktop',
            'ml-0',
            { 'has-text-white': navtransparent },
          ]"
          aria-label="menu"
          @click="isOpen = !isOpen"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div :class="['navbar-menu', { 'is-active': isOpen }]">
        <div class="navbar-start">
          <router-link
            @click="isOpen = false"
            :class="[
              'navbar-item',
              'has-text-weight-bold',
              { 'has-text-white': navtransparent },
            ]"
            :to="'/' + $route.params.locale + '/inhalte'"
          >
            {{ $t("components.navigation.links.features") }}
          </router-link>

          <router-link
            @click="isOpen = false"
            :class="[
              'navbar-item',
              'has-text-weight-bold',
              { 'has-text-white': navtransparent },
            ]"
            :to="'/' + $route.params.locale + '/hotline'"
          >
            {{ $t("components.navigation.links.hotline") }}
          </router-link>

          <router-link
            @click="isOpen = false"
            :class="[
              'navbar-item',
              'has-text-weight-bold',
              'mr-0',
              { 'has-text-white': navtransparent },
            ]"
            :to="'/' + $route.params.locale + '/wartung'"
          >
            {{ $t("components.navigation.links.service") }}
          </router-link>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <router-link
                @click="isOpen = false"
                class="button is-primary"
                :to="'/' + $route.params.locale + '/register'"
              >
                {{ $t("components.navigation.cta") }}
                <span class="icon ml-2">
                  <fa icon="angle-double-right" class="icon" />
                </span>
              </router-link>
              <a
                @click="isOpen = false"
                class="button"
                target="_blank"
                rel="noopener noreferrer"
                :href="`https://rep.guide/${$root.$i18n.locale}/login`"
              >
                {{ $t("components.navigation.login") }}
                <span class="icon ml-2 mr-1">
                  <fa icon="sign-in-alt" class="icon" />
                </span>
              </a>
              <div class="is-hidden-touch">
                <language-select />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LanguageSelect from "./language-select.vue";
import { generateSearchParams } from "../utm.js";

export default {
  components: { LanguageSelect },
  data() {
    return {
      isOpen: false,
      topScroll: this.fancy,
    };
  },
  props: {
    fancy: Boolean,
    utm: { type: Object, default: () => {} },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.topScroll = window.pageYOffset < 100;
    });
  },
  computed: {
    navtransparent() {
      return this.fancy ? (this.isOpen ? false : this.topScroll) : false;
    },
    utmString() {
      const UTM = { ...this.utm, utm_content: "main_navigation" };
      return generateSearchParams(UTM);
    },
  },
};
</script>

<style lang="scss">
.navbar {
  box-shadow: 0 8px 16px rgba(0 0 0 / 0.2);
  &.is-transparent {
    box-shadow: none;
    background: transparent !important;
  }
}
.navbar,
.navbar-item {
  transition: all 0.2s ease;
}

.navbar .navbar-brand a img {
  height: 100%;
  padding: 7%;
  @media screen and (min-width: 1024px) {
    padding-top: 0;
  }
}

.navbar a.navbar-item {
  position: relative;
  transition: none;
  margin-right: 0.25rem;
}
.navbar a.navbar-item.router-link-active {
  color: #e94a10;
  background: #e94a1022;
}

.navbar .navbar-placeholder {
  transform: skewX(-12.56deg);
}
.navbar a.navbar-item:hover {
  color: white;
  background: none;
  transition: color 0.2s 0.1s ease;
}
@media (max-width: 786px) {
  .navbar a.navbar-item:hover {
    color: #4a4a4a;
  }
}
.navbar a.navbar-item:hover::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2.5rem;
  background: #e94a10;
  z-index: -10;
  transform: skewX(-12.56deg);
  -webkit-animation: slide 0.2s forwards;
  -webkit-animation-delay: 0.1s;
  animation: slide 0.2s forwards;
  animation-delay: 0.1s;
}
@-webkit-keyframes slide {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes slide {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
