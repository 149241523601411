<template>
  <div id="page-construction">
    <navigation fancy :utm="utm" />

    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-multiline is-mobile is-vcentered py-6 my-6">
            <div
              class="
                column column-voodoo
                is-10-tablet is-offset-1-tablet is-5-desktop is-5-fullhd
              "
            >
              <div class="card is-shadowless has-background-light">
                <div class="card-content">
                  <h2 class="title is-marginless is-size-3 is-size-4-mobile">
                    {{ $t("views.construction.hero.headline") }}
                  </h2>
                </div>
              </div>
              <a
                href="#constructionform"
                class="
                  hero-cta
                  is-block
                  py-3
                  px-5
                  has-background-primary has-text-white
                  title
                  is-4
                "
              >
                {{ $t("views.construction.hero.cta") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <a
        href="#constructionintro"
        class="hero-scroller has-text-white has-text-centered"
      >
        <fa icon="angle-double-down" class="fa-2x" />
      </a>
    </section>

    <section
      class="section is-medium has-background-light"
      id="constructionform"
    >
      <div class="container">
        <h2 class="title is-size-2 is-size-4-mobile text-dark-600">
          {{ $t("views.construction.form.headline") }}
        </h2>
        <p class="mb-6">
          {{ $t("views.construction.form.description") }}
        </p>
        <!-- <p class="mb-2 has-background-white p-4 is-rounded">
          {{ $t("views.construction.form.international.hint") }}
          <br />
          <a
            href="https://airtable.com/embed/shrjT0wV9vnUOYB95"
            target="_blank"
          >
            {{ $t("views.construction.form.international.link") }}
          </a>
        </p> -->
      </div>
      <div class="container">
        <component
          :is="'script'"
          src="https://static.airtable.com/js/embed/embed_snippet_v1.js"
          async
        ></component>

        <iframe
          class="airtable-embed airtable-dynamic-height"
          :src="getConstructionForm()"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="2743"
          style="
            background: transparent;
            border: 1px solid #ccc;
            border-radius: 6px;
          "
        ></iframe>
      </div>
    </section>

    <section class="section is-medium" id="constructionintro">
      <div class="container">
        <h2 class="title is-size-2 is-size-4-mobile">
          {{ $t("views.construction.intro.headline") }}
        </h2>
        <p class="mb-2">
          {{ $t("views.construction.intro.paragraph1") }}
        </p>
        <p class="mt-2 mb-2 has-background-light p-4 is-rounded">
          {{ $t("views.construction.intro.paragraph2") }}
        </p>
      </div>
    </section>

    <quote />

    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import quote from "../components/sections/quote_new.vue";
import footerNavigation from "../components/footerNavigation.vue";
import { generateSearchParams } from "../utm.js";

export default {
  name: "construction",
  props: {
    utm: { type: Object, default: () => {} },
  },
  computed: {
    utmString() {
      const UTM = { ...this.utm, utm_content: "landingpage_construction" };
      return generateSearchParams(UTM);
    },
  },
  methods: {
    getConstructionForm() {
      if (this.$root.$i18n.locale === "de") {
        return "https://airtable.com/embed/shryizIqCeaUPYB4w";
      } else {
        return "https://airtable.com/embed/shrjT0wV9vnUOYB95";
      }
    },
  },
  components: {
    navigation,
    quote,
    footerNavigation,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#page-construction .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../assets/repguide-background-construction.jpg") no-repeat center
      bottom fixed;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  #savetime.section,
  #screenshots.section {
    padding: 1.5rem 0.5rem;
  }

  #savetime .card .card-content {
    padding: 1rem;
  }
}
</style>
