<template>
  <main id="app">
    <router-view :utm="utm" />
    <country-select />
  </main>
</template>

<script>
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import countrySelect from "./components/country-select.vue";

polyfillCountryFlagEmojis();

export default {
  name: "app",
  components: { countrySelect },
  data() {
    return {
      utm: {},
    };
  },
  mounted() {
    const { utm_campaign, utm_content, utm_medium, utm_source } =
      this.$router.currentRoute?.query;
    this.$root.$i18n.locale = this.$route.params.locale;

    this.utm = {
      utm_source: utm_source || "repguide",
      utm_medium: utm_medium || "website-de",
      utm_campaign: utm_campaign || "organic",
      utm_content: utm_content || "",
    };
  },
};
</script>

<style lang="scss">
@import "./styles.scss";

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

#app {
  font-family: "Twemoji Country Flags", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.has-custom-skew {
  transform: skewX(-12.56deg);
}

ul.is-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.column-tag {
  position: absolute;
  top: 0.75em;
  right: 0.95em;
  transform: skewX(-12.56deg);
  &.is-top-right {
    top: 0 !important;
    right: 0.15em !important;
  }
}

@media (min-width: 769px) {
  .cut-left + .column-tag:not(.is-top-right) {
    right: unset !important;
    left: 2.75em;
  }
}

.button {
  font-family: "Twemoji Country Flags", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.column-background {
  min-height: 180px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
  overflow: hidden;
  &.cut-left:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--cut-color, white);
    top: 0;
    right: 100%;
    transform: skewX(-12.56deg);
    transform-origin: bottom;
  }
  &.cut-right:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--cut-color, white);
    top: 0;
    left: 100%;
    transform: skewX(-12.56deg);
    transform-origin: top;
  }
  @media (max-width: 769px) {
    &.cut-left.no-skew-mobile:before,
    &.cut-right.no-skew-mobile:after {
      display: none;
    }
  }

  &.has-background-techdata {
    background-image: url("assets/inhalte/techdata.jpg");
  }
  &.has-background-parts {
    background-image: url("assets/inhalte/parts.jpg");
  }
  &.has-background-filling {
    background-image: url("assets/inhalte/lubricants.jpg");
  }
  &.has-background-torque {
    background-image: url("assets/inhalte/torque.jpg");
  }
  &.has-background-service {
    background-image: url("assets/inhalte/service.jpg");
  }
  &.has-background-support {
    background-image: url("assets/inhalte/support.jpg");
  }
  &.has-background-diagrams {
    background-image: url("assets/inhalte/diagrams.jpg");
  }
  &.has-background-engine {
    background-image: url("assets/inhalte/engine.jpg");
  }
  &.has-background-errors {
    background-image: url("assets/inhalte/errors.jpg");
  }
  &.has-background-locations {
    background-image: url("assets/inhalte/locations.jpg");
  }
  &.has-background-systemdata {
    background-image: url("assets/inhalte/systemdata.jpg");
  }
}
</style>
