export const CAMPAIGN_100years = {
  utm_source: "repguide_marketing",
  utm_campaign: "100years",
  utm_medium: "printmailing",
  utm_content: "new_customers",
};

export const CAMPAIGN_100years_returning = {
  utm_source: "repguide_marketing",
  utm_campaign: "100years",
  utm_medium: "printmailing",
  utm_content: "returning_customers",
};

export const generateSearchParams = (params) => {
  const PARAMS = new URLSearchParams(window.location.search);

  Object.entries(params).forEach(([key, value]) => {
    if (String(value).length) PARAMS.set(key, value);
  });

  return PARAMS.size ? `?${PARAMS.toString()}` : "";
};
