<template>
  <div :class="['modal', { 'is-active': open }]">
    <div class="modal-background" @click="close()" />
    <div
      class="modal-content"
      style="
        width: calc(100% - 20px);
        max-width: 1200px !important;
        border-radius: 8px;
      "
    >
      <section class="has-background-white py-4 px-3">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="level mb-3">
              <div class="level-left">
                <div class="level-item">
                  <h3 class="title is-4 mt-0 mb-0">
                    {{ $t("components.countrySelect.headline") }}
                  </h3>
                </div>
              </div>
              <div class="level-right has-text-right">
                <language-select verbose />
              </div>
            </div>
            <hr class="mt-2 mb-0" />
          </div>
          <div v-if="suggestion" class="column is-12">
            <div class="columns">
              <div class="column is-6-tablet is-4-desktop is-3-widescreen">
                <a
                  class="button is-responsive is-fullwidth has-text-left is-align-items-center is-justify-content-space-between"
                  @click="
                    setCountry(suggestion, {
                      redirectCrossOrigin: true,
                    })
                  "
                >
                  <span
                    v-if="suggestion.AppCountry.countryData"
                    class="icon is-size-4 mr-0"
                  >
                    {{ suggestion.AppCountry.countryData.flag }}
                  </span>
                  <span class="is-flex-grow-1 has-text-weight-semibold">
                    {{ translations[suggestion.AppCountry.country_code] }}
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            v-for="country in partners.filter(
              (c) =>
                !suggestion ||
                c.AppCountry.country_code !== suggestion.AppCountry.country_code
            )"
            :key="country.AppCountry.country_code"
            class="column is-12-tablet is-6-desktop is-4-widescreen"
          >
            <a
              class="button is-fullwidth is-justify-content-space-between bg-transparent"
              style="height: unset"
              @click="
                setCountry(country, {
                  redirectCrossOrigin: true,
                })
              "
            >
              <div
                class="is-flex is-flex-grow-1 is-flex-direction-column is-align-items-flex-start has-text-left"
                style="white-space: normal"
              >
                <span class="is-flex is-align-items-center">
                  <span
                    v-if="country.AppCountry.countryData"
                    class="is-size-5 mr-1"
                  >
                    {{ country.AppCountry.countryData.flag }}
                  </span>
                  <span class="has-text-weight-semibold">
                    {{ translations[country.AppCountry.country_code] }}
                  </span>
                </span>
                <small v-if="country && country.company">
                  {{ country.company }}
                </small>
              </div>
              <span
                v-if="
                  selectedPartner &&
                  selectedPartner.AppCountry.country_code ===
                    country.AppCountry.country_code
                "
                class="icon"
              >
                <fa icon="check" aria-hidden="true" />
              </span>
            </a>
          </div>
        </div>
        <article class="mt-5 message is-primary">
          <div class="message-body">
            <h5 class="title is-5 has-text-primary mt-0 mb-2">
              {{ $t("components.countrySelect.help.headline") }}
            </h5>
            <p>
              {{ $t("components.countrySelect.help.description") }}
            </p>
            <div class="mt-2 has-text-centered">
              <a href="mailto:repguide@fricke.de" class="button is-primary">
                {{ $t("components.countrySelect.help.cta") }}
              </a>
            </div>
          </div>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
import languageSelect from "./language-select.vue";
import { store, mutations } from "../store";
import { eventBus } from "../event-bus";

export default {
  components: { languageSelect },
  data() {
    return {
      open: false,
      suggestion: null,
      TOP_LEVEL_DOMAIN: (
        window.location.hostname.match(/\.co\.uk/)?.[0] ||
        window.location.hostname.split(".").pop()
      ).toLowerCase(),
    };
  },
  mounted() {
    // NOTE: add event listener for country selection
    eventBus.$on("open-country-select", () => {
      this.open = true;
    });

    eventBus.$on("partners-loaded", () => {
      // NOTE: localstorage is unique per origin (eu, de, at, ch, co.uk)
      // NOTE: it must therefore only contain language codes valid for the current origin
      // NOTE: this will prevent infinite loops when switching between origins (see L119)
      // NOTE: for the sake of everyone involved, ISO codes are written in caps, TLDs in lowercase
      const LOCALSTORAGE_COUNTRY = this.getCountry(
        localStorage.getItem("country")?.toUpperCase()
      );
      const CLOUDFLARE_COUNTRY = this.getCountry(
        this.$router.currentRoute.query?.country?.toUpperCase()
      );

      if (CLOUDFLARE_COUNTRY) {
        if (this.TOP_LEVEL_DOMAIN === CLOUDFLARE_COUNTRY.custom_tld) {
          // NOTE: matching local domains will save w/o user interaction
          this.setCountry(CLOUDFLARE_COUNTRY);
        } else {
          // NOTE: country by cloudflare does not match top level domain
          // NOTE: use cloudflare country as a suggestion and verify by user
          this.setCountry(CLOUDFLARE_COUNTRY, {
            isSuggestion: true,
          });
        }
      } else if (LOCALSTORAGE_COUNTRY) {
        // NOTE: edge case where cloudflare country isn't set but localstorage is
        // NOTE: we will honor the localstorage country w/o the need for user interaction
        this.setCountry(LOCALSTORAGE_COUNTRY);
      } else {
        // NOTE: edge case where cloudflare country isn't set
        this.open = true;
      }
    });
  },
  methods: {
    close() {
      // NOTE: only allow closing if user has selected a country
      if (this.selectedPartner) this.open = false;
    },
    setCountry(country, args = {}) {
      const DEFAULT_OPTIONS = {
        isSuggestion: false,
        redirectCrossOrigin: false,
      };
      const OPTIONS = Object.assign(DEFAULT_OPTIONS, args);

      this.suggestion = null;
      this.open = false;
      const { origin } = new URL(window.location.href);
      if (OPTIONS.isSuggestion) {
        // NOTE: open country select with active suggestion
        this.suggestion = country;
        this.open = true;
      } else if (
        origin &&
        origin.match(/https\:\/{2}repguide.[a-z]+/gi) &&
        ![country.custom_tld].includes(this.TOP_LEVEL_DOMAIN)
      ) {
        // NOTE: target country tld differs from current domain and isn't supported
        // NOTE: hard redirect the user to the correct origin incl. all params
        // WARN: CLEARING LOCALSTORAGE AS AN ADDITIONAL SECURITY MEASURE
        // WARN: LEAVING POTENTIAL REDIRECTS IN LOCALSTORAGE COULD CAUSE INFINITE LOOPS
        localStorage.clear("country");

        const PATHNAME = window.location.pathname.replace(
          /^\/[a-z]{2}\//gi,
          `/${this.$root.$i18n.locale}/`
        );
        const QUERY = new URLSearchParams(window.location.search);
        QUERY.set("country", country.AppCountry.country_code);

        window.location.href = `https://repguide.${
          country.custom_tld
        }${PATHNAME}?${QUERY.toString()}`;
      } else {
        // NOTE: target country is supported on this origin
        // NOTE: save country to localstorage and update params if necessary
        mutations.setSelectedPartner(country);
        localStorage.setItem(
          "country",
          country.AppCountry.country_code.toUpperCase()
        );

        if (
          this.$router.currentRoute.query.country !==
          country.AppCountry.country_code
        ) {
          this.$router.push({
            params: { locale: this.$root.$i18n.locale },
            query: {
              ...this.$router.currentRoute.query,
              country: country.AppCountry.country_code,
            },
          });
        }
      }
      eventBus.$emit("changeCountry", country);
    },
    getCountry(code) {
      if (!code) return null;
      return store.partners.find(
        (country) =>
          country.AppCountry.country_code.toUpperCase() === code.toUpperCase()
      );
    },
    getTranslation(code) {
      return this.translations[code];
    },
  },
  computed: {
    partners() {
      return store.partners;
    },
    selectedPartner() {
      return store.selectedPartner;
    },
    translations() {
      return {
        AT: this.$t("components.countrySelect.countries.AT"),
        BE: this.$t("components.countrySelect.countries.BE"),
        BA: this.$t("components.countrySelect.countries.BA"),
        BG: this.$t("components.countrySelect.countries.BG"),
        HR: this.$t("components.countrySelect.countries.HR"),
        CZ: this.$t("components.countrySelect.countries.CZ"),
        DK: this.$t("components.countrySelect.countries.DK"),
        GB: this.$t("components.countrySelect.countries.GB"),
        EE: this.$t("components.countrySelect.countries.EE"),
        FI: this.$t("components.countrySelect.countries.FI"),
        FR: this.$t("components.countrySelect.countries.FR"),
        DE: this.$t("components.countrySelect.countries.DE"),
        HU: this.$t("components.countrySelect.countries.HU"),
        IE: this.$t("components.countrySelect.countries.IE"),
        IT: this.$t("components.countrySelect.countries.IT"),
        LV: this.$t("components.countrySelect.countries.LV"),
        LT: this.$t("components.countrySelect.countries.LT"),
        LU: this.$t("components.countrySelect.countries.LU"),
        NL: this.$t("components.countrySelect.countries.NL"),
        NO: this.$t("components.countrySelect.countries.NO"),
        PL: this.$t("components.countrySelect.countries.PL"),
        PT: this.$t("components.countrySelect.countries.PT"),
        RO: this.$t("components.countrySelect.countries.RO"),
        RS: this.$t("components.countrySelect.countries.RS"),
        SK: this.$t("components.countrySelect.countries.SK"),
        SI: this.$t("components.countrySelect.countries.SI"),
        ES: this.$t("components.countrySelect.countries.ES"),
        SE: this.$t("components.countrySelect.countries.SE"),
        CH: this.$t("components.countrySelect.countries.CH"),
        UA: this.$t("components.countrySelect.countries.UA"),
      };
    },
  },
};
</script>

<style></style>
