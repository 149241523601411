<template>
  <div>
    <section class="section is-medium has-background-white" id="metrics">
      <div class="container">
        <div>
          <h2 class="no-margin-top title is-2 is-size-4-mobile">
            {{ $t("views.index.metrics.headline") }}
          </h2>
          <p>
            {{ $t("views.index.metrics.description") }}
          </p>
        </div>
        <div class="columns is-multiline mt-6">
          <div
            v-for="comparison in comparisons"
            :key="comparison.title"
            class="column mb-6 is-full is-one-third-widescreen"
          >
            <div>
              <h5 class="subtitle is-marginless">
                {{ comparison.title }}
              </h5>
              <br />
              <strong>
                <fa class="fa-fw has-text-primary" :icon="['rg', 'logo']" />
                {{ comparison.new }}
              </strong>
              <progress class="progress is-primary" value="14" max="300" />
              <p class="is-flex" style="min-height: 60px">
                <span>
                  <fa class="fa-fw mr-1" :icon="comparison.icon" />
                </span>
                <span>{{ comparison.old }}</span>
              </p>
              <progress class="progress" value="256" max="300"></progress>
            </div>
          </div>
        </div>
        <div class="columns mt-6">
          <div
            class="column is-10-desktop is-offset-1-desktop is-8-widescreen is-offset-2-widescreen"
          >
            <p class="has-text-left" style="font-size: clamp(1em, 5vw, 2em)">
              <i18n path="views.index.metrics.highlight.prefix.text">
                <strong>{{
                  $t("views.index.metrics.highlight.prefix.strong")
                }}</strong>
              </i18n>
            </p>
            <p
              class="has-text-centered"
              style="
                font-size: clamp(2em, 15vw, 7em);
                line-height: clamp(0em, 10vw, 0.75em);
              "
            >
              <i18n
                path="views.index.metrics.highlight.total.text"
                tag="strong"
              >
                <span class="has-text-primary">11</span>
              </i18n>
            </p>
            <p class="has-text-right" style="font-size: clamp(1em, 5vw, 2em)">
              <i18n path="views.index.metrics.highlight.suffix.text">
                <strong>{{
                  $t("views.index.metrics.highlight.suffix.strong")
                }}</strong>
                <small class="has-text-grey-light">*</small>
              </i18n>
            </p>
          </div>
        </div>
      </div>
    </section>
    <p class="has-text-grey has-text-right p-2">
      {{ $t("views.index.metrics.highlight.footnote") }}
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    comparisons() {
      return [
        {
          icon: "book",
          title: this.$t("views.index.metrics.comparisons.torque.title"),
          new: this.$t("views.index.metrics.comparisons.torque.new"),
          old: this.$t("views.index.metrics.comparisons.torque.old"),
        },
        {
          icon: "laptop",
          title: this.$t("views.index.metrics.comparisons.filter.title"),
          new: this.$t("views.index.metrics.comparisons.filter.new"),
          old: this.$t("views.index.metrics.comparisons.filter.old"),
        },
        {
          icon: "book",
          title: this.$t("views.index.metrics.comparisons.refrigerants.title"),
          new: this.$t("views.index.metrics.comparisons.refrigerants.new"),
          old: this.$t("views.index.metrics.comparisons.refrigerants.old"),
        },
      ];
    },
  },
};
</script>
