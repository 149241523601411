<template>
  <div id="page-hotline">
    <navigation fancy :utm="utm" />

    <section class="hero">
      <div class="hero-body">
        <div class="container pt-6">
          <div class="columns is-multiline is-mobile is-vcentered py-6 my-6">
            <div
              class="
                column column-voodoo
                is-12-mobile
                is-10-tablet
                is-offset-1-tablet
                is-5-desktop
                is-5-fullhd
              "
            >
              <div class="card is-shadowless has-background-light">
                <div class="card-content">
                  <h2 class="title is-marginless is-size-3 is-size-4-mobile">
                    {{ $t("views.hotline.hero.headline") }}
                  </h2>
                </div>
              </div>
              <router-link
                :to="'/' + $route.params.locale + '/register' + utmString"
                class="
                  hero-cta
                  is-block
                  py-3
                  px-5
                  has-background-primary has-text-white
                  title
                  is-4
                "
              >
                {{ $t("views.hotline.hero.cta") }}
              </router-link>
            </div>
            <div
              class="
                column
                is-10-mobile
                is-8-tablet
                is-6-desktop
                is-5-widescreen
                is-offset-1-mobile
                is-offset-2-tablet
                is-offset-0-desktop
                is-offset-1-widescreen
              "
            >
              <div
                v-if="forceNumberDisplay || $root.$i18n.locale == 'de'"
                class="
                  is-flex
                  is-flex-direction-column
                  is-align-items-flex-end
                  is-flex-wrap-wrap
                "
              >
                <div class="has-text-right has-text-light pb-4">
                  <fa icon="headset" class="fa-5x" />
                </div>
                <div class="card p-3 has-arrow-to-headset">
                  <a
                    href="tel:+49 040 253 324 510"
                    class="
                      title
                      is-3 is-marginless
                      has-text-primary
                      hover-underline
                    "
                  >
                    +49 (0)40 253 324 510
                  </a>
                  <p class="has-text-grey mt-2">
                    {{ $t("views.hotline.hero.quickinfo.availability") }}
                    <br />
                    {{ $t("views.hotline.hero.quickinfo.service") }}*
                  </p>
                </div>
              </div>
              <div v-else class="column is-full">
                <article class="message is-primary">
                  <div class="message-body">
                    <strong>{{ $t("views.hotline.message.headline") }}</strong>
                    <br />
                    <i18n path="views.hotline.message.description" tag="p">
                      <a
                        class="has-text-weight-semibold"
                        rel="noopener noreferrer"
                        :href="'mailto:' + supportContact"
                        target="_blank"
                        >{{ supportContact }}</a
                      >
                    </i18n>
                    <div style="margin: 0.5rem -1rem 0">
                      <button
                        class="button is-primary is-light is-text"
                        @click="forceNumberDisplay = true"
                      >
                        {{ $t("views.hotline.message.show-number") }}
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        href="#hotlineintro"
        class="hero-scroller has-text-white has-text-centered"
      >
        <fa icon="angle-double-down" class="fa-2x" />
      </a>
    </section>

    <p class="has-text-right p-1 has-background-white">
      *
      <a href="#hotlineconditions" class="has-text-grey hover-underline">
        {{ $t("views.hotline.hero.quickinfo.asterisk") }}
      </a>
    </p>

    <section class="section is-medium pt-5" id="hotlineintro">
      <div class="container">
        <div
          class="notification mt-6 mb-6 has-background-primary-light"
          style="
            border-left: 4px solid #e94a10;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          "
        >
          <div class="columns is-mobile is-align-items-center">
            <div class="column is-narrow pl-4 has-text-primary">
              <fa icon="exclamation" class="fa-2x icon" />
            </div>
            <div class="column has-text-primary-dark">
              <i18n path="views.hotline.intro.warning-text" tag="p">
                <strong>
                  {{ $t("views.hotline.intro.warning-strong") }}
                </strong>
                <a
                  target="_blank"
                  :href="granitSupportContact"
                  class="has-text-weight-semibold has-text-primary"
                >
                  {{ $t("views.hotline.intro.warning-link") }}
                  <fa icon="external-link-alt" />
                </a>
              </i18n>
            </div>
          </div>
        </div>
        <h2 class="title is-size-2 is-size-4-mobile text-dark-600">
          {{ $t("views.hotline.intro.headline") }}
        </h2>
        <p class="mb-5">
          {{ $t("views.hotline.intro.description") }}
        </p>
        <div class="columns">
          <div class="column is-half is-flex is-mobile">
            <div class="card py-3 px-4">
              <h4
                class="
                  subtitle
                  is-5
                  has-text-weight-bold
                  mb-2
                  mt-0
                  has-text-primary
                "
              >
                {{ $t("views.hotline.intro.when.headline") }}
              </h4>
              <p>
                {{ $t("views.hotline.intro.when.text") }}
              </p>
            </div>
          </div>
          <div class="column is-half is-flex">
            <div class="card py-3 px-4">
              <h4
                class="
                  subtitle
                  is-5
                  has-text-weight-bold
                  mb-2
                  mt-0
                  has-text-primary
                "
              >
                {{ $t("views.hotline.intro.how.headline") }}
              </h4>
              <p>
                {{ $t("views.hotline.intro.how.text") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section is-medium has-background-light" id="hotlineprofile">
      <div class="container">
        <div class="columns is-multiline is-align-items-center">
          <div
            class="
              column
              ml-0
              is-full-tablet is-5-desktop is-4-widescreen
              card
              columns
              is-multiline is-align-items-center
            "
          >
            <div
              class="
                column
                card-image
                is-5-tablet
                is-10-mobile
                is-offset-1-mobile
                is-10-desktop
                is-offset-1-desktop
              "
            >
              <figure class="image is-square">
                <img
                  :alt="$t('views.hotline.heino.profile.alt')"
                  src="../assets/hotline-profile-heino.jpg"
                  style="border-radius: 9999px"
                />
              </figure>
            </div>
            <div class="column card-content has-text-centered p-3">
              <h3 class="title is-size-4 has-text-primary mt-0 mb-6">
                Heino Willenbrock
              </h3>
              <ul
                class="
                  is-size-6
                  has-repguide-bullets has-text-left has-text-weight-bold
                "
              >
                <li class="mb-4">
                  {{ $t("views.hotline.heino.profile.bullet-1") }}
                </li>
                <li class="mb-4">
                  {{ $t("views.hotline.heino.profile.bullet-2") }}
                </li>
                <li class="mb-4">
                  {{ $t("views.hotline.heino.profile.bullet-3") }}
                </li>
                <li class="mb-4">
                  {{ $t("views.hotline.heino.profile.bullet-4") }}
                </li>
                <li class="mb-4">
                  {{ $t("views.hotline.heino.profile.bullet-5") }}
                </li>
                <li class="mb-4">
                  {{ $t("views.hotline.heino.profile.bullet-6") }}
                </li>
              </ul>
            </div>
          </div>
          <div class="column is-offset-1-fullhd px-6">
            <h2 class="has-text-primary title is-3 mt-0">
              {{ $t("views.hotline.heino.headline") }}
            </h2>
            <h3 class="mb-3">
              {{ $t("views.hotline.heino.interview.slogan.headline") }}:
            </h3>
            <p class="has-text-grey-dark">
              {{ $t("views.hotline.heino.interview.slogan.statement") }}
            </p>
            <h3 class="mb-3">
              {{ $t("views.hotline.heino.interview.challenge.headline") }}:
            </h3>
            <p class="has-text-grey-dark">
              {{ $t("views.hotline.heino.interview.challenge.statement") }}
            </p>
            <h3 class="mb-3">
              {{ $t("views.hotline.heino.interview.repguide.headline") }}:
            </h3>
            <p class="has-text-grey-dark">
              {{ $t("views.hotline.heino.interview.repguide.statement") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section is-medium pb-6 pt-5" id="hotlineconditions">
      <div class="container">
        <h2 class="subtitle is-3 mb-5">
          {{ $t("views.hotline.conditions.headline") }}
        </h2>
        <ul class="is-size-6 has-repguide-bullets has-text-left">
          <li class="mb-4" v-html="$t('views.hotline.conditions.bullet-1')" />
          <li class="mb-4" v-html="$t('views.hotline.conditions.bullet-2')" />
          <li class="mb-4" v-html="$t('views.hotline.conditions.bullet-3')" />
        </ul>
      </div>
    </section>

    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import footerNavigation from "../components/footerNavigation.vue";
import { store } from "../store";
import { generateSearchParams } from "../utm.js";

export default {
  name: "hotline",
  props: {
    utm: { type: Object, default: () => {} },
  },
  computed: {
    utmString() {
      const UTM = { ...this.utm, utm_content: "landingpage_hotline" };
      return generateSearchParams(UTM);
    },
    supportContact() {
      return store.selectedPartner?.support_contact || "repguide@fricke.de";
    },
    granitSupportContact() {
      const BASE_DOMAIN =
        store.selectedPartner?.GranitShop?.base_domain || "granit-parts.com";
      return `https://${BASE_DOMAIN}/e/service/contact${this.utmString}`;
    },
  },
  components: {
    navigation,
    footerNavigation,
  },
  data() {
    return { forceNumberDisplay: false };
  },
};
</script>

<style lang="scss">
ul.has-repguide-bullets {
  li {
    padding-left: 2.5rem;
    line-height: 1.5rem;
  }
}

.has-arrow-to-headset {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -30px;
    right: 4rem;
    width: 0;
    height: 0;
    border: 15px solid white;
    border-top-color: transparent;
    border-left-color: transparent;
  }
}
.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}
</style>
