<template>
  <div :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background" />
    <div
      class="modal-card"
      style="border-radius: 8px; width: calc(400px + 2rem + 4px)"
    >
      <section class="has-background-white py-2 px-1">
        <div class="column">
          <p class="mb-2 has-text-weight-semibold level is-mobile">
            {{ $t("ag23.signature-input.headline") }}
            <button class="button is-small" @click="resetSignature()">
              {{ $t("ag23.signature-input.reset") }}
            </button>
          </p>
          <div
            class="has-background-white-ter"
            style="
              border: 2px dashed #ddd !important;
              border-radius: 8px;
              width: 404px !important;
              height: 204px !important;
            "
          >
            <canvas
              ref="canvas"
              width="400"
              height="200"
              style="
                cursor: crosshair;
                touch-action: none;
                width: 400px;
                height: 200px;
              "
              @mousedown="(e) => setCursorPosition(e)"
              @mousemove="(e) => drawSignature(e)"
              @mouseup="() => stopDrawing()"
              @touchstart.prevent="(e) => setCursorPosition(e)"
              @touchmove.prevent="(e) => drawSignature(e)"
              @touchend.prevent="() => stopDrawing()"
            >
            </canvas>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-flex-direction-row-reverse">
        <button
          class="button is-primary mr-0 ml-2"
          :disabled="!didinteract"
          @click="saveSignature()"
        >
          {{ $t("ag23.signature-input.done") }}
        </button>
        <button
          class="button is-ghost"
          @click="
            () => {
              resetSignature();
              isOpen = false;
            }
          "
        >
          {{ $t("ag23.signature-input.cancel") }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "signature-modal",
  data() {
    return {
      isOpen: false,
      position: { x: 0, y: 0 },
      didinteract: false,
      timeout: null,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    setCursorPosition(e) {
      const RECT = this.$refs.canvas.getBoundingClientRect();
      this.position.x = (e.touches?.[0] || e).clientX - RECT.left;
      this.position.y = (e.touches?.[0] || e).clientY - RECT.top;
    },
    drawSignature(e) {
      if (!e.touches?.length && e.buttons !== 1) return;

      clearTimeout(this.timeout);

      const CTX = this.$refs.canvas.getContext("2d");

      CTX.beginPath();

      CTX.lineWidth = 3;
      CTX.lineCap = "round";
      CTX.strokeStyle = "#000000";

      CTX.moveTo(this.position.x, this.position.y);
      this.setCursorPosition(e);
      CTX.lineTo(this.position.x, this.position.y);

      CTX.stroke();
    },
    resetSignature() {
      this.setCursorPosition({ offsetX: 0, offsetY: 0 });

      const CTX = this.$refs.canvas.getContext("2d");
      CTX.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);

      clearTimeout(this.timeout);
      this.didinteract = false;
    },
    stopDrawing() {
      this.timeout = setTimeout(() => {
        this.didinteract = true;
      }, 750);
    },
    saveSignature() {
      this.$emit("save", this.$refs.canvas.toDataURL());
      this.resetSignature();
      this.isOpen = false;
    },
  },
};
</script>
