<template>
  <div>
    <navigation />
    <section class="section is-medium" id="imprint">
      <div class="container">
        <div class="content">
          <h1 class="title is-2 is-size-4-mobile">
            {{ $t("views.imprint.headline") }}
          </h1>
          <hr class="is-placeholder is-hidden-mobile" />

          <i18n path="views.imprint.project.text" tag="p">
            <!-- non-translated proper name -->
            <strong>FRI:X Fricke Innovation Lab</strong>
          </i18n>
          <div class="columns is-multiline">
            <div
              v-for="(partner, idx) in partners"
              :key="idx"
              class="column is-12-mobile is-6-tablet is-4-widescreen is-flex"
            >
              <article class="card is-flex-grow-1 p-3">
                <h5
                  class="my-1 is-flex is-align-items-center is-justify-content-space-between"
                >
                  <span
                    v-if="partner.AppCountry.countryData"
                    class="is-size-4 mr-2"
                  >
                    {{ partner.AppCountry.countryData.flag }}
                  </span>
                  <span class="is-flex-grow-1 has-text-weight-semibold">
                    {{ translations[partner.AppCountry.country_code] }}
                  </span>
                </h5>
                <rule icon="map-pin">{{
                  $t("views.imprint.cards.address")
                }}</rule>
                <!-- non-translated address -->
                <address class="has-text-dark">
                  <strong>{{ partner.company }}</strong>
                  <br />
                  {{ partner.address.address_line_1 }}<br />
                  <template v-if="partner.address.address_line_2">
                    {{ partner.address.address_line_2 }}
                    <br />
                  </template>
                  <template v-if="partner.address.country">
                    {{ partner.address.country.cca2
                    }}<span
                      v-if="
                        partner.address.country.cca2 && partner.address.zip_code
                      "
                      >-</span
                    ></template
                  >{{ partner.address.zip_code }}
                  {{ partner.address.city_name }}
                </address>

                <rule icon="user">{{ $t("views.imprint.cards.contact") }}</rule>
                <h5 v-if="partner.ceos && partner.ceos.length > 0">
                  <template v-for="ceo in partner.ceos">
                    {{ ceo.firstName }}
                    {{ ceo.lastName }}
                  </template>
                </h5>
                <h5 v-else>Hans-Peter Fricke</h5>

                <rule icon="paragraph">{{
                  $t("views.imprint.cards.ustid")
                }}</rule>
                <span class="has-text-dark">
                  {{ partner.vat_number || "-" }}
                </span>
              </article>
            </div>
            <div
              class="column is-12-mobile is-6-tablet is-4-widescreen is-flex"
              v-if="loading"
            >
              <article
                class="is-shadowless card is-flex-grow-1 p-3 background-white-smoke is-flex is-align-items-center is-justify-content-center has-text-grey"
                style="border: 1px solid #efefef"
              >
                <span>{{ $t("views.imprint.cards.loading") }}</span>
              </article>
            </div>
          </div>
          <hr />
          <p>
            <i18n path="views.imprint.email.text">
              <span>repguide&#x00040;fricke.de</span>
            </i18n>
            <br />
            <i18n path="views.imprint.internet.text">
              <span>https://www.repguide.de</span>
            </i18n>
          </p>
          <i18n path="views.imprint.management.text" tag="p">
            <span>Hans-Peter Fricke, Holger Wachholtz</span>
          </i18n>
          <p>
            {{ $t("views.imprint.ust-id") }}
          </p>

          <h5 class="mb-0">{{ $t("views.imprint.liability.headline") }}</h5>
          <p>
            {{ $t("views.imprint.liability.description") }}
          </p>

          <h5 class="mb-0">
            {{
              $t("views.imprint.copyright.headline", {
                year: new Date().getFullYear(),
              })
            }}
          </h5>
          <p>
            {{ $t("views.imprint.copyright.description") }}
          </p>

          <h3>{{ $t("views.imprint.odr-vo.headline") }}</h3>
          <i18n path="views.imprint.odr-vo.description.text" tag="p">
            <a href="http://ec.europa.eu/consumers/odr" target="_blank">{{
              $t("views.imprint.odr-vo.description.here")
            }}</a>
          </i18n>
        </div>
      </div>
    </section>
    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import footerNavigation from "../components/footerNavigation.vue";
import rule from "../components/rule.vue";
import { store } from "../store";

export default {
  name: "impressum",
  components: {
    navigation,
    footerNavigation,
    rule,
  },
  computed: {
    partners() {
      return store.partners;
    },
    loading() {
      return store.loading;
    },
    translations() {
      return {
        AT: this.$t("components.countrySelect.countries.AT"),
        BE: this.$t("components.countrySelect.countries.BE"),
        BA: this.$t("components.countrySelect.countries.BA"),
        BG: this.$t("components.countrySelect.countries.BG"),
        HR: this.$t("components.countrySelect.countries.HR"),
        CZ: this.$t("components.countrySelect.countries.CZ"),
        DK: this.$t("components.countrySelect.countries.DK"),
        GB: this.$t("components.countrySelect.countries.GB"),
        EE: this.$t("components.countrySelect.countries.EE"),
        FI: this.$t("components.countrySelect.countries.FI"),
        FR: this.$t("components.countrySelect.countries.FR"),
        DE: this.$t("components.countrySelect.countries.DE"),
        HU: this.$t("components.countrySelect.countries.HU"),
        IE: this.$t("components.countrySelect.countries.IE"),
        IT: this.$t("components.countrySelect.countries.IT"),
        LV: this.$t("components.countrySelect.countries.LV"),
        LT: this.$t("components.countrySelect.countries.LT"),
        LU: this.$t("components.countrySelect.countries.LU"),
        NL: this.$t("components.countrySelect.countries.NL"),
        NO: this.$t("components.countrySelect.countries.NO"),
        PL: this.$t("components.countrySelect.countries.PL"),
        PT: this.$t("components.countrySelect.countries.PT"),
        RO: this.$t("components.countrySelect.countries.RO"),
        RS: this.$t("components.countrySelect.countries.RS"),
        SK: this.$t("components.countrySelect.countries.SK"),
        SI: this.$t("components.countrySelect.countries.SI"),
        ES: this.$t("components.countrySelect.countries.ES"),
        SE: this.$t("components.countrySelect.countries.SE"),
        CH: this.$t("components.countrySelect.countries.CH"),
        UA: this.$t("components.countrySelect.countries.UA"),
      };
    },
  },
};
</script>
