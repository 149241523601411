<template>
  <div :class="['dropdown', 'is-right', { 'is-active': open }]">
    <div class="dropdown-trigger">
      <button
        class="button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        @click.stop="open = !open"
      >
        <span class="is-size-4">
          <span v-if="currentLanguage">
            {{ currentLanguage.flag }}
          </span>
          <span v-else>
            <fa icon="globe" class="fa-fw" />
          </span>
        </span>
        <span v-if="verbose && currentLanguage" class="pl-2">
          {{ $t("components.languageSelect.langs." + currentLanguage.code) }}
        </span>
        <span class="icon is-small">
          <fa icon="chevron-down" aria-hidden="true" />
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu" @click.stop>
      <div class="dropdown-content has-text-left">
        <div class="dropdown-item py-1">
          <p>{{ $t("components.languageSelect.headline") }}</p>
        </div>
        <hr class="dropdown-divider" />
        <a
          href="#"
          v-for="lang in langs"
          :key="lang.code"
          :class="[
            'dropdown-item',
            'py-0',
            'pr-4',
            'is-flex',
            'is-align-items-center',
            'is-justify-content-space-between',
          ]"
          @click.prevent="setLanguage(lang.code)"
        >
          <span class="is-size-4">{{ lang.flag }}</span>
          <span class="is-flex-grow-1 has-text-weight-bold px-2">
            {{ getIntlDisplayLanguage(lang.code) }}
            ({{ getIntlDisplayLanguage(lang.code, lang.code) }})
          </span>
          <span
            v-if="lang.code === currentLanguage && currentLanguage.code"
            class="icon"
          >
            <fa icon="check" aria-hidden="true" />
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store";

export default {
  props: {
    verbose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    closeDropdown() {
      this.open = false;
    },
    setLanguage(code) {
      this.$root.$i18n.locale = code;
      // change locale in url to reflect language change
      this.$router.push({
        name: this.$route.name,
        params: { locale: code },
        query: this.$route.query,
      });
      // localStorage.setItem("locale", code);
      // NOTE: our widget only supports german and english as of now.
      window.usercentrics?.updateLanguage?.(code === "de" ? "de" : "en");
      this.closeDropdown();
    },
    getIntlDisplayLanguage(country, language = this.$root.$i18n.locale) {
      const DISPLAY_NAME = new Intl.DisplayNames([language], {
        type: "language",
      });
      return DISPLAY_NAME.of(country);
    },
  },
  computed: {
    currentLanguage() {
      return this.langs?.find((l) => l.code === this.$root.$i18n.locale);
    },
    langs() {
      return store.AVAILABLE_LOCALES;
    },
  },
  created() {
    window.addEventListener("click", this.closeDropdown);
  },
  destroyed() {
    window.removeEventListener("click", this.closeDropdown);
  },
};
</script>
