<template>
  <div id="page-career">
    <navigation fancy :utm="utm" />

    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-multiline is-mobile is-vcentered py-6 my-6">
            <div
              class="column column-voodoo is-10-tablet is-offset-1-tablet is-5-desktop is-5-fullhd"
            >
              <div class="card is-shadowless has-background-light">
                <div class="card-content">
                  <h2 class="title is-marginless is-size-3 is-size-4-mobile">
                    {{ $t("views.career.hero.headline") }}
                  </h2>
                </div>
              </div>
              <a
                href="#careerform"
                class="hero-cta is-block py-3 px-5 has-background-primary has-text-white title is-4"
              >
                {{ $t("views.career.hero.cta") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <a
        href="#careerintro"
        class="hero-scroller has-text-white has-text-centered"
      >
        <fa icon="angle-double-down" class="fa-2x" />
      </a>
    </section>

    <section class="section is-medium has-background-light" id="careerform">
      <div class="container">
        <h2 class="title is-size-2 is-size-4-mobile text-dark-600">
          {{ $t("views.career.form.headline") }}
        </h2>
        <p class="mb-6">
          {{ $t("views.career.form.description") }}
        </p>
      </div>
      <div class="container">
        <div id="join-widget"></div>
        <component
          :is="'script'"
          data-mount-in="#join-widget"
          :src="getJoinWidgetUrl()"
          defer
        >
        </component>
      </div>
    </section>

    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import footerNavigation from "../components/footerNavigation.vue";

export default {
  name: "career-page",
  props: {
    utm: { type: Object, default: () => {} },
  },
  components: {
    navigation,
    footerNavigation,
  },
  methods: {
    getJoinWidgetUrl() {
      if (this.$root.$i18n.locale === "de") {
        return "https://join.com/api/widget/bundle/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXR0aW5ncyI6eyJzaG93Q2F0ZWdvcnlGaWx0ZXIiOnRydWUsInNob3dMb2NhdGlvbkZpbHRlciI6dHJ1ZSwic2hvd0VtcGxveW1lbnRUeXBlRmlsdGVyIjp0cnVlLCJsYW5ndWFnZSI6ImRlIiwiam9ic1BlclBhZ2UiOjI1fSwiam9icyI6eyJjYXRlZ29yeUlkcyI6W10sImVtcGxveW1lbnRUeXBlSWRzIjpbXX0sImRlc2lnbiI6eyJzaG93TG9nbyI6dHJ1ZSwic2hvd0xvY2F0aW9uIjp0cnVlLCJzaG93RW1wbG95bWVudFR5cGUiOnRydWUsInNob3dDYXRlZ29yeSI6dHJ1ZSwiY29sb3JzIjp7IndpZGdldCI6eyJiYWNrZ3JvdW5kIjoiI0VDRUNFQyIsImZpbHRlckJvcmRlciI6IiNEMEQ0REEiLCJwYWdpbmF0aW9uIjoiI0U4NDkxMCJ9LCJqb2JDYXJkIjp7ImJvcmRlciI6IiM1RjY4NzUiLCJzaGFkb3ciOiIjRDBENERBIiwiYmFja2dyb3VuZCI6IiNGRkZGRkYiLCJwcmltYXJ5VGV4dCI6IiNFODQ5MTAiLCJzZWNvbmRhcnlUZXh0IjoiIzVGNjg3NSJ9fX0sInZlcnNpb24iOjIsImNvbXBhbnlQdWJsaWNJZCI6IjNmNzk1NDk4MTdiMDMxOGUyNjMxOTVjNzI1ZTNmNTViIiwiaWF0IjoxNjc3ODQwMjgxLCJqdGkiOiIxNTVkNmVlYi02YmJhLTQ2YTUtODEyYy04YWQxMzVhZGVmMzkifQ.HqFhBVvlFd4dO_f81guv9lGa9x-cKZD24xGOqufc5bE";
      } else {
        return "https://join.com/api/widget/bundle/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXR0aW5ncyI6eyJzaG93Q2F0ZWdvcnlGaWx0ZXIiOnRydWUsInNob3dMb2NhdGlvbkZpbHRlciI6dHJ1ZSwic2hvd0VtcGxveW1lbnRUeXBlRmlsdGVyIjp0cnVlLCJsYW5ndWFnZSI6ImVuIiwiam9ic1BlclBhZ2UiOjI1fSwiam9icyI6eyJjYXRlZ29yeUlkcyI6W10sImVtcGxveW1lbnRUeXBlSWRzIjpbXX0sImRlc2lnbiI6eyJzaG93TG9nbyI6dHJ1ZSwic2hvd0xvY2F0aW9uIjp0cnVlLCJzaG93RW1wbG95bWVudFR5cGUiOnRydWUsInNob3dDYXRlZ29yeSI6dHJ1ZSwiY29sb3JzIjp7IndpZGdldCI6eyJiYWNrZ3JvdW5kIjoiI0VDRUNFQyIsImZpbHRlckJvcmRlciI6IiNEMEQ0REEiLCJwYWdpbmF0aW9uIjoiI0U4NDkxMCJ9LCJqb2JDYXJkIjp7ImJvcmRlciI6IiM1RjY4NzUiLCJzaGFkb3ciOiIjRDBENERBIiwiYmFja2dyb3VuZCI6IiNGRkZGRkYiLCJwcmltYXJ5VGV4dCI6IiNFODQ5MTAiLCJzZWNvbmRhcnlUZXh0IjoiIzVGNjg3NSJ9fX0sInZlcnNpb24iOjIsImNvbXBhbnlQdWJsaWNJZCI6IjNmNzk1NDk4MTdiMDMxOGUyNjMxOTVjNzI1ZTNmNTViIiwiaWF0IjoxNjc3ODU4NjA2LCJqdGkiOiJhZjkyYzkzNS1iYjAxLTRiNDYtOGJkYi05MDJhMWY2MTVkOTQifQ.HVYyOEmR1Rg7QmwivfZN5EswCB-VY_FD88YIeVuQAro";
      }
    },
  },
};
</script>
