<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline is-mobile is-vcentered py-6 my-6">
          <div
            class="column column-voodoo is-10-tablet is-offset-1-tablet is-5-desktop is-5-fullhd"
          >
            <div
              style="max-width: 500px; margin: 0 auto 50px"
              class="is-hidden-desktop"
            >
              <mock-preview />
            </div>

            <div class="card is-shadowless has-background-light">
              <div class="card-content">
                <h2 class="title is-marginless is-size-3 is-size-4-mobile">
                  {{ $t("views.index.hero.headline") }}
                </h2>
              </div>
            </div>
            <router-link
              :to="'/' + $route.params.locale + '/register' + utmString"
              class="hero-cta is-block py-3 px-5 has-background-primary has-text-white title is-4"
            >
              {{ $t("views.index.hero.cta") }}
            </router-link>
          </div>
          <div class="column is-offset-1 is-hidden-touch">
            <div>
              <mock-preview />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="#about" class="hero-scroller has-text-white has-text-centered">
      <fa icon="angle-double-down" class="fa-2x" />
    </a>
  </section>
</template>

<script>
import mockPreview from "../mock-preview.vue";
import { generateSearchParams } from "../../utm.js";

export default {
  props: {
    utm: { type: Object, default: () => {} },
  },
  components: {
    mockPreview,
  },
  computed: {
    utmString() {
      const UTM = { ...this.utm, utm_content: "index_hero" };
      return generateSearchParams(UTM);
    },
  },
};
</script>

<style lang="scss">
#app .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/bg-tractor.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

.column-voodoo {
  position: relative;
  z-index: 0;
  .card {
    position: relative;
    width: 80%;
    z-index: 1;
  }
  .hero-cta {
    position: relative;
    margin-top: -20px;
    float: right;
    max-width: 300px;
    z-index: 3;
    border-radius: 4px;
  }
}

.hero .box .title {
  background: #efefef;
  padding: 1.2rem;
  margin: -1.2rem -1.2rem 1.2rem;
}
.hero-scroller {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: block;
  padding: 30px 0;
}
</style>
