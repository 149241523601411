<template>
  <section
    class="section is-medium has-background-light is-clipped"
    id="signup"
  >
    <div class="container">
      <div class="columns is-vcentered is-desktop">
        <div
          class="
            column
            is-relative is-5-desktop is-6-widescreen
            has-text-white
            is-depth-guide
          "
        >
          <div class="repguide-seperator has-background-primary" />
          <h2 class="title is-2 is-size-4-mobile mb-6 has-text-white">
            {{ $t("views.index.cta.headline") }}
          </h2>
          <ul class="is-size-5 has-repguide-bullets has-repguide-bullets-white">
            <li class="mb-5 is-relative">
              {{ $t("views.index.cta.bullets.signup") }}
            </li>
            <li class="mb-5 is-relative">
              {{ $t("views.index.cta.bullets.email") }}
            </li>
            <li class="mb-5 is-relative">
              {{ $t("views.index.cta.bullets.password") }}
            </li>
          </ul>
        </div>
        <div class="column is-offset-1 has-text-centered">
          <router-link
            :to="'/' + $route.params.locale + '/register' + utmString"
            class="
              button
              is-hidden-touch is-large is-primary is-size-5-mobile is-cta
            "
          >
            <i18n path="views.index.cta.button.long" tag="span">
              <br />
            </i18n>
          </router-link>
          <router-link
            :to="'/' + $route.params.locale + '/register' + utmString"
            class="
              button
              is-hidden-desktop is-large is-white is-size-5-mobile
              my-6
              is-cta
            "
          >
            <i18n path="views.index.cta.button.short" tag="span">
              <br />
            </i18n>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { generateSearchParams } from "../../utm.js";

export default {
  props: {
    utm: { type: Object, default: () => {} },
  },
  computed: {
    utmString() {
      const UTM = { ...this.utm, utm_content: "index_bottom" };
      return generateSearchParams(UTM);
    },
  },
};
</script>

<style lang="scss">
ul.has-repguide-bullets-white li:before {
  background: white !important;
}
.is-depth-guide {
  z-index: 0;
}
.repguide-seperator {
  position: absolute;
  height: 1000px;
  top: -150px;
  width: 2000px;
  z-index: -1;
  right: -13.5px;
  @media (min-width: 1024px) {
    right: -50px;
    transform: skewX(-12.56deg);
  }
}
.button.is-cta {
  height: 100px;
}
</style>
