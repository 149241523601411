<template>
  <div>
    <navigation fancy :utm="utm" />
    <hero :utm="utm" />

    <features />
    <benefits />
    <quote />
    <about />
    <metrics />
    <counseling />
    <cta :utm="utm" />
    <news />
    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import hero from "../components/sections/hero.vue";
import counseling from "../components/sections/counseling.vue";
import news from "../components/sections/news.vue";
import about from "../components/sections/about.vue";
import features from "../components/sections/features.vue";
import benefits from "../components/sections/benefits.vue";
import metrics from "../components/sections/metrics.vue";
import quote from "../components/sections/quote_new.vue";
import cta from "../components/sections/cta.vue";
import footerNavigation from "../components/footerNavigation.vue";

export default {
  name: "index",
  props: {
    utm: Object,
  },
  components: {
    navigation,
    hero,
    counseling,
    news,
    about,
    features,
    benefits,
    metrics,
    quote,
    cta,
    footerNavigation,
  },
};
</script>
