<template>
  <div>
    <navigation />
    <section class="section is-medium" id="privacy">
      <div class="container">
        <div class="content">
          <h1 class="title is-2 is-size-4-mobile">
            {{ $t("views.meeting.headline") }}
          </h1>
          <hr class="is-placeholder is-hidden-mobile" />
          <div class="columns">
            <div class="column is-one-quarter">
              <img
                class="mb-5"
                src="../assets/RepGuide-Notebook-Tablet-Phone-Daniel.png"
                alt="Rep.Guide Screenshot"
              />
            </div>
            <div class="column">
              <p class="is-size-5">
                <i18n path="views.meeting.description.text">
                  <strong>{{ $t("views.meeting.description.future") }}</strong>
                  <strong>{{
                    $t("views.meeting.description.database")
                  }}</strong>
                </i18n>
              </p>
              <div class="notification">
                <div class="columns">
                  <div class="column is-one-fifth">
                    <figure class="pt-1">
                      <fa-layers class="fa-3x fa-fw">
                        <fa
                          class="has-text-grey-lighter"
                          icon="circle"
                          transform="grow-5"
                        />
                        <fa
                          icon="pause"
                          class="has-text-grey"
                          transform="shrink-5"
                        />
                      </fa-layers>
                    </figure>
                  </div>
                  <div class="column">
                    <i18n path="views.meeting.detail.text" tag="p">
                      <strong>{{ $t("views.meeting.detail.contact") }}</strong>
                    </i18n>
                    <a
                      href="mailto:repguide@fricke.de?subject=Interesse%20an%20Rep.Guide%20Infoveranstaltung"
                      class="button is-primary"
                    >
                      {{ $t("views.meeting.detail.cta") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import footerNavigation from "../components/footerNavigation.vue";

export default {
  name: "impressum",
  components: {
    navigation,
    footerNavigation,
  },
};
</script>
