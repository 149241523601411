<template>
  <div>
    <navigation />
    <section class="section is-medium" id="features">
      <div class="container">
        <h2 class="title is-size-2 is-size-4-mobile">
          {{ $t("views.features.headline") }}
        </h2>
        <p class="mb-6">
          <i18n path="views.features.description.text">
            <strong>{{ $t("views.features.description.strong") }}</strong>
          </i18n>
        </p>
        <div
          :key="feature.title + '-image'"
          v-for="(feature, index) in features"
          style="margin: clamp(1em, 7vw, 7em) 0"
          :class="[
            'columns is-vcentered',
            { 'is-flex-direction-row-reverse': index % 2 },
          ]"
        >
          <div :class="['column is-one-third-tablet is-relative']">
            <div
              :class="[
                'column-background no-skew-mobile cut-left cut-right',
                index % 2 ? 'cut-left' : 'cut-right',
                feature.imageClass,
              ]"
            />
            <strong
              class="column-tag has-background-primary has-text-white px-2 py-1"
              v-if="feature.tag"
            >
              {{ feature.tag }}
            </strong>
          </div>
          <div class="column is-half-widescreen">
            <h4 class="title is-4 mt-0 mb-3 has-text-primary">
              {{ feature.title }}
            </h4>
            <p class="content mb-0">
              {{ feature.description }}
            </p>
            <p v-if="feature.optional" class="content is-size-7 has-text-grey">
              {{ feature.optional }}
            </p>
          </div>
        </div>
      </div>
      <div class="has-text-centered pt-6">
        <router-link
          :to="'/' + $route.params.locale + '/register' + utmString"
          class="button is-primary is-medium"
        >
          {{ $t("views.features.cta") }}
        </router-link>
      </div>
    </section>
    <quote />
    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import footerNavigation from "../components/footerNavigation.vue";
import quote from "../components/sections/quote.vue";
import { generateSearchParams } from "../utm.js";

export default {
  name: "features",
  props: {
    utm: { type: Object, default: () => {} },
  },
  computed: {
    utmString() {
      const UTM = { ...this.utm, utm_content: "features_bottom" };
      return generateSearchParams(UTM);
    },
    features() {
      return [
        {
          title: this.$t("views.features.list.techdata.title"),
          description: this.$t("views.features.list.techdata.description"),
          imageClass: "has-background-techdata",
        },
        {
          title: this.$t("views.features.list.parts.title"),
          description: this.$t("views.features.list.parts.description"),
          imageClass: "has-background-parts",
        },
        {
          title: this.$t("views.features.list.filling.title"),
          description: this.$t("views.features.list.filling.description"),
          imageClass: "has-background-filling",
        },
        {
          title: this.$t("views.features.list.torque.title"),
          description: this.$t("views.features.list.torque.description"),
          imageClass: "has-background-torque",
        },
        {
          title: this.$t("views.features.list.service.title"),
          description: this.$t("views.features.list.service.description"),
          imageClass: "has-background-service",
        },
        {
          title: this.$t("views.features.list.support.title"),
          description: this.$t("views.features.list.support.description"),
          imageClass: "has-background-support",
          tag: this.$t("views.features.list.support.tag"),
        },
        {
          title: this.$t("views.features.list.diagrams.title"),
          description: this.$t("views.features.list.diagrams.description"),
          imageClass: "has-background-diagrams",
          optional: this.$t("views.features.list.diagrams.optional"),
        },
        {
          title: this.$t("views.features.list.errors.title"),
          description: this.$t("views.features.list.errors.description"),
          imageClass: "has-background-errors",
          optional: this.$t("views.features.list.diagrams.optional"),
        },
        {
          title: this.$t("views.features.list.system.title"),
          description: this.$t("views.features.list.system.description"),
          imageClass: "has-background-systemdata",
          tag: this.$t("views.features.list.system.tag"),
        },
      ];
    },
  },
  components: {
    navigation,
    footerNavigation,
    quote,
  },
};
</script>
