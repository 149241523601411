<template>
  <div id="page-voucher">
    <navigation fancy :utm="utm" />

    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-multiline is-mobile is-vcentered py-6 my-6">
            <div
              class="column column-voodoo is-10-tablet is-offset-1-tablet is-5-desktop is-5-fullhd"
            >
              <div class="card is-shadowless has-background-light">
                <div class="card-content">
                  <h2 class="title is-marginless is-size-3 is-size-4-mobile">
                    {{ $t("views.voucher.hero.headline") }}
                  </h2>
                </div>
              </div>
              <a
                href="#voucherform"
                class="hero-cta is-block py-3 px-5 has-background-primary has-text-white title is-4"
              >
                {{ $t("views.voucher.hero.cta") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <a
        href="#voucherform"
        class="hero-scroller has-text-white has-text-centered"
      >
        <fa icon="angle-double-down" class="fa-2x" />
      </a>
    </section>

    <section class="section is-medium" id="voucherform">
      <div class="container pb-6">
        <h2 class="title is-size-2 is-size-4-mobile text-dark-600">
          {{ $t("views.voucher.form.headline") }}
        </h2>
        <p class="mb-5" v-html="$t('views.voucher.form.description')" />
      </div>
      <div class="container">
        <div class="columns">
          <div
            class="column columns is-multiline is-offset-1-tablet is-10-tablet is-offset-2-desktop is-8-desktop is-offset-3-fullhd is-6-fullhd"
          >
            <div class="column is-12">
              <div class="timeline">
                <div class="is-relative is-clipped">
                  <strong
                    :class="[
                      'timeline-marker',
                      {
                        'is-active':
                          !loading && !error && granit.value === null,
                      },
                    ]"
                  >
                    1
                  </strong>
                  <span class="timeline-rule" />
                </div>
                <div
                  :class="['pb-5 timeline-section', { 'is-disabled': error }]"
                >
                  <p class="mb-2 mt-1 has-text-weight-semibold">
                    {{ $t("views.voucher.form.granit.label") }} *
                  </p>
                  <div class="field has-addons">
                    <div class="control has-icons-right is-expanded">
                      <input
                        v-model="granit.input"
                        @input="
                          () => {
                            granit.valid = null;
                            granit.checked = false;
                            granit.value = null;
                          }
                        "
                        @keyup.enter="granit.input.trim() && checkGranit()"
                        :class="['input formelement']"
                        type="text"
                        autocomplete="off"
                        :placeholder="$t('views.voucher.form.granit.label')"
                        :disabled="error || loading || granit.value !== null"
                      />
                      <span class="icon is-small is-right">
                        <button
                          v-if="granit.value !== null"
                          @click="resetForm()"
                          class="button is-small is-dark"
                          :disabled="error || loading"
                          style="
                            pointer-events: all;
                            border-radius: 2px !important;
                            margin-right: 3px;
                          "
                        >
                          <fa icon="times" />
                        </button>
                      </span>
                    </div>
                    <p class="control mb-0" v-if="granit.value === null">
                      <button
                        :class="[
                          'button',
                          { 'is-primary': granit.input.trim().length },
                          { 'is-loading': granit.loading },
                        ]"
                        @click="checkGranit()"
                        :disabled="
                          error ||
                          loading ||
                          !granit.input.trim().length ||
                          granit.loading
                        "
                      >
                        {{ $t("views.voucher.form.granit.next") }}
                      </button>
                    </p>
                  </div>
                  <div v-if="granit.checked">
                    <div
                      v-if="granit.valid === true"
                      class="has-text-success mt-2"
                    >
                      <strong>
                        <fa icon="check" />
                        {{ $t("views.voucher.form.granit.valid") }}
                      </strong>
                    </div>
                    <div v-else class="has-text-danger mt-2">
                      <strong class="has-text-danger-dark">
                        {{ $t("views.voucher.form.granit.invalid-headline") }}
                      </strong>
                      <br />
                      {{
                        granit.valid === null
                          ? $t("views.voucher.form.granit.invalid-text-unknown")
                          : $t("views.voucher.form.granit.invalid-text-active")
                      }}
                      <br />
                      <br />
                      <i18n
                        path="views.voucher.form.support"
                        class="has-text-danger-dark"
                      >
                        <a
                          class="has-text-danger-dark has-text-weight-bold"
                          :href="'mailto:' + selectedPartner.support_contact"
                          >{{ selectedPartner.support_contact }}</a
                        >
                      </i18n>
                      <div class="is-flex is-justify-content-flex-end mt-2">
                        <!-- NOTE: no utm params for router-links as the router handles these -->
                        <router-link
                          v-if="granit.valid === null"
                          class="button is-dark"
                          :to="`/${$route.params.locale}/register`"
                        >
                          {{ $t("views.voucher.form.granit.cta") }}
                        </router-link>
                        <a
                          v-else
                          :href="`https://rep.guide/${
                            $route.params.locale + utmString
                          }`"
                          class="button is-dark"
                          target="_blank"
                        >
                          {{ $t("views.voucher.form.granit.login") }}
                          <fa icon="external-link-alt" class="fa-fw ml-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="is-relative is-clipped">
                  <strong
                    :class="[
                      'timeline-marker',
                      {
                        'is-active':
                          !loading &&
                          !error &&
                          granit.value !== null &&
                          voucher.value === null,
                      },
                    ]"
                  >
                    2
                  </strong>
                  <span class="timeline-rule" />
                </div>
                <div
                  :class="[
                    'pb-5 timeline-section',
                    { 'is-disabled': error || granit.value === null },
                  ]"
                >
                  <p class="mb-2 mt-1 has-text-weight-semibold">
                    {{ $t("views.register.account.form.promo") }} *
                  </p>
                  <div class="field has-addons">
                    <div class="control has-icons-right is-expanded">
                      <input
                        v-model="voucher.input"
                        @input="
                          () => {
                            voucher.valid = null;
                            voucher.value = null;
                            voucher.input = voucher.input.toUpperCase();
                          }
                        "
                        @keyup.enter="voucher.input.trim() && checkVoucher()"
                        :class="['input formelement']"
                        type="text"
                        autocomplete="off"
                        :placeholder="$t('views.register.account.form.promo')"
                        :disabled="
                          error ||
                          loading ||
                          granit.value === null ||
                          voucher.value !== null
                        "
                      />
                      <span class="icon is-small is-right">
                        <button
                          v-if="voucher.value !== null"
                          @click="resetVoucher()"
                          class="button is-small is-dark"
                          :disabled="error || loading || voucher.loading"
                          style="
                            pointer-events: all;
                            border-radius: 2px !important;
                            margin-right: 3px;
                          "
                        >
                          <fa icon="times" />
                        </button>
                      </span>
                    </div>
                    <p class="control mb-0" v-if="voucher.value === null">
                      <button
                        :class="[
                          'button',
                          { 'is-primary': voucher.input.trim().length },
                          { 'is-loading': voucher.loading },
                        ]"
                        @click="checkVoucher()"
                        :disabled="
                          error ||
                          loading ||
                          !voucher.input.trim().length ||
                          voucher.loading
                        "
                      >
                        {{ $t("views.voucher.form.voucher.check") }}
                      </button>
                    </p>
                  </div>
                  <div
                    v-if="voucher.valid === false"
                    class="has-text-danger mt-2"
                  >
                    <strong class="has-text-danger-dark">
                      {{ $t("views.voucher.form.voucher.invalid.headline") }}
                    </strong>
                    <br />
                    <span v-if="!voucher.error" class="has-text-danger">
                      {{
                        $t("views.voucher.form.voucher.invalid.error:generic")
                      }}
                    </span>
                    <span v-else class="has-text-danger">
                      {{
                        $t(
                          `views.voucher.form.voucher.invalid.error:${
                            voucher.error.message || "generic"
                          }`
                        )
                      }}
                      <br />
                      <small class="has-text-danger-dark">
                        {{
                          $t("views.voucher.form.voucher.invalid.error-code")
                        }}
                        <code
                          style="user-select: all"
                          class="has-text-danger-dark has-text-weight-bold"
                        >
                          {{ voucher.error.code }}
                        </code>
                      </small>
                    </span>
                  </div>
                  <div
                    v-if="voucher.valid === true"
                    class="has-text-success mt-2"
                  >
                    <strong class="has-text-success-dark">
                      <fa icon="check" />
                      {{ $t("views.voucher.form.voucher.valid.headline") }}
                      <span v-if="voucher.info">
                        "{{
                          $t(`marketing-campaigns.${voucher.info.i18n_key}`)
                        }}"
                      </span>
                    </strong>
                    <ul class="has-repguide-bullets" v-if="voucher.info">
                      <li
                        class="has-text-primary-dark has-text-weight-semibold"
                        v-for="offer in voucher.info.MarketingOffers"
                        :key="offer.id"
                      >
                        {{ $t(`marketing-offers.${offer.offer_key}`) }}
                      </li>
                    </ul>
                    <div
                      v-if="pswVouchers.includes(voucher.value)"
                      class="columns"
                    >
                      <div class="column" style="margin-left: 3.5rem">
                        <p class="mb-2 has-text-weight-semibold">
                          {{ $t("ag23.signature") }}
                        </p>
                        <div
                          @click="$refs.signatureModal.open()"
                          :style="{
                            border: '2px dashed !important',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            borderColor:
                              showValidation && !signature ? 'red' : '#ddd',
                          }"
                        >
                          <div
                            style="height: 200px"
                            class="is-flex p-2 is-justify-content-center"
                          >
                            <img
                              v-if="signature"
                              :src="signature"
                              style="max-height: 100%; width: auto"
                            />
                          </div>
                          <div
                            style="border-top: 1px solid #ddd"
                            class="has-text-centered mx-3 py-1 has-text-grey"
                          >
                            {{ $t("ag23.your-signature") }}
                          </div>
                        </div>
                        <p class="mt-5 mb-2 has-text-weight-semibold">
                          {{ $t("ag23.complete-name") }}
                        </p>
                        <div class="field">
                          <div class="control">
                            <input
                              v-model="name"
                              :class="[
                                'input formelement',
                                { 'is-danger': showValidation && !name },
                              ]"
                              type="text"
                              autocomplete="full-name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="is-relative is-clipped">
                  <strong
                    :class="[
                      'timeline-marker',
                      {
                        'is-active':
                          !loading && !error && voucher.value !== null,
                      },
                    ]"
                  >
                    3
                  </strong>
                  <span class="timeline-rule" />
                </div>
                <div
                  :class="[
                    'pb-5 mb-2 timeline-section',
                    { 'is-disabled': error || voucher.value === null },
                  ]"
                >
                  <p class="mb-2 mt-1 has-text-weight-semibold">
                    {{ $t("views.voucher.form.additional.headline") }}
                  </p>
                  <label class="is-mobile columns">
                    <div class="column is-narrow">
                      <input
                        :checked="password"
                        class="checkbox"
                        type="checkbox"
                        style="height: 25px; width: 25px"
                        :disabled="true"
                      />
                      <!-- :disabled="error || loading || granit.value === null || voucher.value === null -->
                    </div>
                    <p class="column" style="word-break: break-word">
                      <span>
                        {{ $t("views.voucher.form.additional.password.label") }}
                      </span>
                      <br />
                      <small class="has-text-grey">
                        {{
                          $t(
                            "views.voucher.form.additional.password.description"
                          )
                        }}
                      </small>
                    </p>
                  </label>

                  <label class="is-mobile columns">
                    <div class="column is-narrow">
                      <input
                        v-model="terms"
                        :class="[
                          'checkbox',
                          { 'is-danger': showValidation && !terms },
                        ]"
                        type="checkbox"
                        style="height: 25px; width: 25px"
                        :disabled="
                          error ||
                          loading ||
                          granit.value === null ||
                          voucher.value === null
                        "
                      />
                    </div>
                    <p class="column" style="word-break: break-word">
                      <i18n path="views.voucher.form.additional.terms">
                        <a :href="generatePartnerLinks().terms" target="_blank">
                          {{ $t("views.register.account.form.terms.terms") }}
                        </a>
                        <span>{{ getCompanyName() }}</span>
                        <a
                          :href="generatePartnerLinks().pricing"
                          target="_blank"
                        >
                          {{ $t("views.register.account.form.terms.pricing") }}
                        </a>
                        <a
                          :href="generatePartnerLinks().privacy"
                          target="_blank"
                        >
                          {{ $t("views.register.account.form.terms.privacy") }}
                        </a>
                      </i18n>
                    </p>
                  </label>
                </div>

                <div class="is-relative is-clipped">
                  <strong
                    :class="[
                      'timeline-marker',
                      { 'is-active': voucher.value !== null },
                    ]"
                  >
                    <fa v-if="error" icon="times" size="xs" />
                    <fa v-else icon="arrow-right" size="xs" />
                  </strong>
                </div>
                <div
                  :class="[
                    'timeline-section',
                    { 'is-disabled': voucher.value === null },
                  ]"
                  style="margin-top: -0.5rem"
                >
                  <div v-if="error" class="notification is-danger">
                    <div class="columns is-mobile is-align-items-center">
                      <div class="column is-narrow pl-4">
                        <fa icon="exclamation" class="fa-2x icon" />
                      </div>
                      <div class="column">
                        <p>
                          <strong>
                            {{ $t("views.voucher.form.error.headline") }}
                          </strong>
                          <br />
                          {{ $t("views.voucher.form.error.description") }}
                          <br />
                          <a
                            class="mt-2 button is-primary"
                            style="height: unset !important"
                            :href="generateMailTo()"
                          >
                            <i18n path="views.voucher.form.error.cta">
                              <br class="is-hidden-tablet" />
                            </i18n>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    v-else
                    @click="reactivate()"
                    :class="[
                      'button is-primary is-fullwidth px-2 is-medium',
                      { 'is-loading': loading },
                    ]"
                    :disabled="
                      error ||
                      loading ||
                      granit.value === null ||
                      voucher.value === null ||
                      (showValidation && invalid)
                    "
                  >
                    {{ $t("views.voucher.form.reactivate") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footerNavigation />
    <SignatureModal
      ref="signatureModal"
      @save="(newSignature) => (signature = newSignature)"
    />
  </div>
</template>

<script>
import { createClient, gql } from "@urql/core";
import navigation from "../components/navigation.vue";
import footerNavigation from "../components/footerNavigation.vue";
import { store } from "../store";
import { generateSearchParams } from "../utm.js";
import SignatureModal from "../components/signature-modal.vue";

const qlClient = createClient({
  url: "https://rg-api-yoga-a4b3749-rvuvxilsia-ez.a.run.app",
  requestPolicy: "network-only",
});

export default {
  name: "page-voucher",
  props: {
    utm: { type: Object, default: () => {} },
  },
  data: () => {
    return {
      pswVouchers: [
        "PSW24REJAHR",
        "PSW24RE-JAHR",
        // "PSW24BAU",
        // "PSW24-BAU",
        "PSW24JAHR",
        "PSW24-JAHR",
      ],
      granit: {
        input: "",
        loading: false,
        valid: null,
        checked: false,
        value: null,
      },
      voucher: {
        input: "",
        loading: false,
        valid: null,
        error: null,
        info: null,
        value: null,
      },
      signature: null,
      name: null,
      password: true,
      terms: false,
      // END form
      loading: false,
      error: false,
      showValidation: false,
    };
  },
  components: {
    navigation,
    footerNavigation,
    SignatureModal,
  },
  methods: {
    resetVoucher() {
      this.terms = false;
      // TEMP workaround for password reset
      // this.password = false;

      this.voucher.input = "";
      this.voucher.loading = false;
      this.voucher.valid = null;
      this.voucher.error = null;
      this.voucher.info = null;
      this.voucher.value = null;

      this.signature = null;
    },
    resetForm() {
      this.resetVoucher();
      this.granit.input = "";
      this.granit.loading = false;
      this.granit.valid = null;
      this.granit.checked = false;
      this.granit.value = null;
    },
    async checkGranit() {
      this.granit.valid = null;
      this.granit.loading = true;

      this.granit.valid = await qlClient
        .query(
          gql`
            query ($pGranitId: String) {
              CanReactivate(granitId: $pGranitId)
            }
          `,
          { pGranitId: this.granit.input.trim() }
        )
        .toPromise()
        .then((result) => result.data?.CanReactivate);

      if (this.granit.valid) this.granit.value = this.granit.input.trim();
      this.granit.loading = false;
      this.granit.checked = true;
    },
    async checkVoucher() {
      this.voucher.valid = null;
      this.voucher.loading = true;

      this.voucher.valid = await qlClient
        .query(
          gql`
            query ($pCode: String!, $pData: JSON) {
              VoucherCheckValidity(code: $pCode, data: $pData) {
                valid_until
                is_valid
                error_code
              }
              MarketingCampaign(code: $pCode) {
                id
                internal_title
                i18n_key
                campaign_slug
                campaign_start
                campaign_end
                campaign_country_ids
                campaign_active
                campaign_configuration
                createdat
                updatedat
                campaign_offer_ids
                MarketingOffers {
                  id
                  offer_key
                  offer_description
                  offer_config
                  createdat
                  updatedat
                  valid_for_country_ids
                }
              }
            }
          `,
          {
            pCode: this.voucher.input.trim(),
            pData: { granitId: this.granit.input.trim() },
          }
        )
        .toPromise()
        .then((result) => {
          this.voucher.error = result.data?.VoucherCheckValidity?.error_code;
          this.voucher.info = result.data?.MarketingCampaign;
          return result.data?.VoucherCheckValidity?.is_valid;
        });

      if (this.voucher.valid) this.voucher.value = this.voucher.input.trim();
      this.voucher.loading = false;
    },
    async reactivate() {
      this.showValidation = true;

      if (this.invalid) return;

      this.loading = true;

      // console.log("SENDING REACTIVATION REQUEST:", {
      //   granit: this.granit.value,
      //   voucher: this.voucher.value,
      //   password: this.password,
      //   terms: this.terms,
      // });
      // TODO: send granit and voucher to new backend endpoint
      // NOTE: this will activate the voucher and send an email, informing the user
      // WARN: if the user requested a password reset, call the PWReset endpoint HERE!
      // NOTE: that endpoint relies on email and id (optional) so we need them returned from the new endpoint
      // TODO: in any case we want to switch to the success page

      qlClient
        .mutation(
          gql`
            mutation ($pCode: String!, $pGranitId: String!, $pData: JSON) {
              ReturnWithVoucher(
                code: $pCode
                granitId: $pGranitId
                data: $pData
              ) {
                campaign_id
                code_id
                code_value
                valid_until
                code_activated
                activation_code
                managing_user_email
                managing_user_id
                error_code
              }
            }
          `,
          {
            pCode: this.voucher.value,
            pGranitId: this.granit.value,
            pData: {
              signature: this.signature || null,
              name: this.name || null,
            },
          }
        )
        .toPromise()
        .then((result) => {
          if (result.data.ReturnWithVoucher.activation_code !== "success") {
            this.loading = false;
            this.error = true;
            return;
          }

          const redirect = () => {
            this.$router.push({
              name: "returned",
              query: {
                country: this.$router.currentRoute.query.country,
                email: result.data.ReturnWithVoucher.managing_user_email,
              },
            });
          };

          if (!this.password) redirect();

          qlClient
            .mutation(
              gql`
                mutation ($email: String!, $id: Int) {
                  PWRequest(email: $email, id: $id)
                }
              `,
              {
                email: result.data.ReturnWithVoucher.managing_user_email,
                id: result.data.ReturnWithVoucher.managing_user_id,
              }
            )
            .toPromise()
            .then(() => redirect());
        });
    },
    generatePartnerLinks() {
      const PARTNER = this.selectedPartner;
      const ORIGIN = `https://repguide.${PARTNER?.custom_tld || "eu"}`;

      const getURL = (path) =>
        `${ORIGIN}/${this.$route.params.locale}${
          path.startsWith("/") ? path : `/${path}`
        }`;

      return ["terms", "pricing", "privacy"].reduce((obj, key) => {
        return { ...obj, [key]: getURL(PARTNER?.[key] || key) };
      }, {});
    },
    getCompanyName() {
      return this.selectedPartner?.company || "Willhelm Fricke SE";
    },
    maskEmail(email) {
      return String(email).replace(
        /^(.)(.*)(.@.*)$/,
        (_, a, b, c) => a + b.replace(/./g, "*") + c
      );
    },
    generateMailTo() {
      let mailto = "mailto:";
      mailto +=
        this.selectedPartner && this.selectedPartner.support_contact
          ? this.selectedPartner.support_contact
          : "repguide@fricke.de";
      mailto +=
        "?subject=" +
        encodeURI(this.$t("views.voucher.form.error.email.subject"));
      mailto +=
        "&body=" +
        encodeURI(
          this.$t("views.voucher.form.error.email.body", {
            granit: this.granit.value,
            voucher: this.voucher.value,
            password: this.password ? "[x]" : "[ ]",
          })
        );
      return mailto;
    },
  },
  computed: {
    selectedPartner() {
      return store.selectedPartner;
    },
    invalid() {
      return (
        !this.granit.value.trim() ||
        !this.voucher.value.trim() ||
        (this.pswVouchers.includes(this.voucher.value.trim()) &&
          (!this.signature || !this.name)) ||
        !this.terms
      );
    },
    utmString() {
      const UTM = { ...this.utm, utm_content: "voucher_reactivation" };
      return generateSearchParams(UTM);
    },
  },
  async mounted() {
    this.granit.input = this.$router.currentRoute.query?.granitid?.trim() || "";
    this.voucher.input = this.$router.currentRoute.query?.code?.trim() || "";

    if (this.granit.input) await this.checkGranit();
    if (this.granit.valid && this.voucher.input) await this.checkVoucher();
  },
};
</script>

<style lang="scss">
.timeline {
  display: grid;
  grid-template-columns: 36px auto;
  gap: 4px 18px;

  .timeline-marker {
    border-radius: 999px;
    width: 36px;
    height: 36px;
    display: grid;
    place-content: center;
    font-size: 1.25rem;
    font-weight: 800;
    user-select: none;
    transition: all 0.3s ease;

    &:not(.is-active) {
      background: lightgrey;
      color: darkgray;
    }

    &.is-active {
      background: #e94a10;
      color: white;
    }
  }

  .timeline-rule {
    position: absolute;
    top: 40px;
    left: calc(50% - 2px);
    background: lightgrey;
    width: 4px;
    height: 100%;
  }
}

.timeline-section {
  transition: all 0.3s ease;
  opacity: 1;
  pointer-events: all;
  filter: grayscale(0%);

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
    filter: grayscale(100%);
  }
}

ul.has-repguide-bullets {
  li {
    padding-left: 3.5rem;
    line-height: 1.5rem;
    position: relative;
    margin-top: 0.75rem;
  }

  li:before {
    content: "";
    display: block;
    position: absolute;
    height: 24px;
    width: 16px;
    background: #e94a10;
    top: 0.1rem;
    left: 1.5rem;
    transform: skewX(-12.56deg);
  }
}

#page-voucher .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../assets/bg-tractor.jpg") no-repeat center bottom fixed;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  #savetime.section,
  #screenshots.section {
    padding: 1.5rem 0.5rem;
  }

  #savetime .card .card-content {
    padding: 1rem;
  }
}

.is-danger.formelement {
  border-color: #f14668 !important;

  &:focus {
    box-shadow: 0 0 0 0.125em rgb(241 70 104 / 25%) !important;
  }
}
</style>
