<template>
  <div id="page-100-years">
    <section
      class="section is-medium pt-3 pb-0"
      style="
        min-height: 100vh;
        display: grid;
        grid-template-rows: auto 1fr;
        place-items: center;
      "
    >
      <div class="is-flex is-justify-content-space-between" style="width: 100%">
        <router-link
          class="button"
          style="place-self: start"
          :to="'/' + $route.params.locale + '/'"
        >
          {{ $t("views.registered.back") }}
        </router-link>
        <language-select />
      </div>
      <div class="container has-text-centered py-6" style="width: 100%">
        <div class="mb-6">
          <router-link :to="'/' + $route.params.locale + '/'">
            <img
              alt="Rep.Guide Logo"
              src="../../assets/RepGuideLogoWhite.svg"
              width="170"
            />
          </router-link>
        </div>
        <h1
          class="title is-2 has-text-white mb-6"
          v-html="$t('views.freebeer.headline')"
        />
        <div class="container is-max-widescreen mb-6 bulma-cant-handle-grid">
          <div
            id="register-card"
            class="card is-flex is-flex-direction-column is-justify-content-space-between p-5 has-text-centered has-background-primary"
            style="border-radius: 0.75rem; width: 100%"
          >
            <header>
              <h3 class="title is-4 is-marginless has-text-white mt-4">
                {{ $t("views.freebeer.register-card.headline") }}
              </h3>
              <p class="mt-1 mb-4" style="color: #ffded2">
                {{ $t("views.freebeer.register-card.description") }}
              </p>
            </header>
            <div class="mt-4">
              <router-link
                class="button is-white is-medium is-fullwidth"
                :to="`/${$route.params.locale}/register`"
              >
                {{ $t("views.freebeer.register-card.cta") }}
              </router-link>
            </div>
          </div>
          <div
            id="info-card"
            class="card is-flex is-flex-direction-column is-justify-content-space-between p-5 has-text-centered has-background-white-ter my-4"
            style="width: 100%; border-radius: 0.75rem"
          >
            <header>
              <h3 class="title is-4 is-marginless mt-4">
                {{ $t("views.freebeer.info-card.headline") }}
              </h3>
              <p class="mt-1 mb-4 has-text-grey">
                {{ $t("views.freebeer.info-card.description") }}
              </p>
            </header>
            <div class="mt-4">
              <router-link
                class="button is-medium is-fullwidth"
                :to="'/' + $route.params.locale + '/'"
                target="_blank"
              >
                {{ $t("views.freebeer.info-card.cta") }}
                <fa
                  icon="external-link-alt"
                  class="fa-fw ml-1 has-text-grey-light"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footerNavigation />
  </div>
</template>

<script>
import languageSelect from "../../components/language-select.vue";
import footerNavigation from "../../components/footerNavigation.vue";

export default {
  name: "page-100-years",
  components: {
    languageSelect,
    footerNavigation,
  },
};
</script>

<style lang="scss">
#page-100-years > .section {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/repguide-background-beer.jpg") no-repeat center bottom
      fixed;
  background-size: cover;
}

.bulma-cant-handle-grid {
  display: grid;
  width: 100%;
  .card {
    width: 100%;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;

    div#register-card > div {
      margin-bottom: 1rem;
    }
    div#info-card {
      border-radius: 0 0.75rem 0.75rem 0 !important;
    }
  }
}
</style>
