<template>
  <div class="is-flex is-align-items-center mt-4 mb-0">
    <span
      class="is-uppercase has-text-weight-medium has-text-grey is-flex-shrink-0"
      :style="{ fontSize: '.875rem' }"
    >
      <fa v-if="icon" :icon="icon" class="fa-fw mr-1 has-text-grey-light" />
      <slot />
    </span>
    <div
      class="has-background-white-ter"
      :style="{
        height: '4px',
        width: '100%',
        borderRadius: '4px',
        marginLeft: '.5rem',
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: [String, Array],
    },
  },
};
</script>
