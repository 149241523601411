var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:[
    'navbar',
    'is-spaced',
    'is-fixed-top',
    { 'is-transparent': _vm.navtransparent } ],attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{attrs:{"to":'/' + _vm.$route.params.locale + '/'}},[(_vm.navtransparent)?_c('img',{attrs:{"alt":"Rep.Guide Logo White","src":require("../assets/RepGuideLogoWhite.svg"),"width":"160"}}):_c('img',{attrs:{"alt":"Rep.Guide Logo","src":require("../assets/RepGuideLogo.svg"),"width":"160"}})]),_c('div',{staticClass:"navbar-item is-hidden-desktop ml-auto"},[_c('language-select')],1),_c('a',{class:[
          'navbar-burger burger',
          'is-hidden-desktop',
          'ml-0',
          { 'has-text-white': _vm.navtransparent } ],attrs:{"role":"button","aria-label":"menu"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])],1),_c('div',{class:['navbar-menu', { 'is-active': _vm.isOpen }]},[_c('div',{staticClass:"navbar-start"},[_c('router-link',{class:[
            'navbar-item',
            'has-text-weight-bold',
            { 'has-text-white': _vm.navtransparent } ],attrs:{"to":'/' + _vm.$route.params.locale + '/inhalte'},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t("components.navigation.links.features"))+" ")]),_c('router-link',{class:[
            'navbar-item',
            'has-text-weight-bold',
            { 'has-text-white': _vm.navtransparent } ],attrs:{"to":'/' + _vm.$route.params.locale + '/hotline'},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t("components.navigation.links.hotline"))+" ")]),_c('router-link',{class:[
            'navbar-item',
            'has-text-weight-bold',
            'mr-0',
            { 'has-text-white': _vm.navtransparent } ],attrs:{"to":'/' + _vm.$route.params.locale + '/wartung'},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t("components.navigation.links.service"))+" ")])],1),_c('div',{staticClass:"navbar-end"},[_c('div',{staticClass:"navbar-item"},[_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"button is-primary",attrs:{"to":'/' + _vm.$route.params.locale + '/register'},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t("components.navigation.cta"))+" "),_c('span',{staticClass:"icon ml-2"},[_c('fa',{staticClass:"icon",attrs:{"icon":"angle-double-right"}})],1)]),_c('a',{staticClass:"button",attrs:{"target":"_blank","rel":"noopener noreferrer","href":("https://rep.guide/" + (_vm.$root.$i18n.locale) + "/login")},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t("components.navigation.login"))+" "),_c('span',{staticClass:"icon ml-2 mr-1"},[_c('fa',{staticClass:"icon",attrs:{"icon":"sign-in-alt"}})],1)]),_c('div',{staticClass:"is-hidden-touch"},[_c('language-select')],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }