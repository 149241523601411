<template>
  <div>
    <navigation />
    <section class="section is-medium" id="agb">
      <div class="container">
        <div class="content">
          <h1 class="title is-2 is-size-4-mobile">
            {{ $t("views.agb.headline") }}
          </h1>
          <hr class="is-placeholder is-hidden-mobile" />

          <div class="notification is-info is-light">
            <p>
              {{ $t("views.agb.description") }}
            </p>
            <p>
              <a
                href="/files/RepGuide-Terms-International-September-2023.pdf"
                target="_blank"
              >
                <button class="button is-info">
                  {{ $t("views.agb.cta") }}
                </button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import footerNavigation from "../components/footerNavigation.vue";

export default {
  name: "agb",
  components: {
    navigation,
    footerNavigation,
  },
};
</script>
