<template>
  <section
    class="section is-medium"
    id="features"
    style="overflow: hidden; max-width: 100vw; padding-top: 6rem"
  >
    <div class="container">
      <h2 class="title is-size-2 is-size-4-mobile mt-0">
        {{ $t("views.index.features.headline") }}
      </h2>
      <p class="mb-6">
        <i18n path="views.index.features.description.text">
          <strong>{{ $t("views.index.features.description.strong") }}</strong>
        </i18n>
      </p>
    </div>

    <div>
      <div class="marquee">
        <router-link
          :key="image.title + index"
          v-for="(image, index) in [...images, ...images]"
          style="width: 420px"
          :to="'/' + $route.params.locale + '/inhalte#' + image.title"
        >
          <div
            :class="[
              'column-background cut-left cut-right keep-skew',
              image.class,
            ]"
          >
            <strong
              class="column-tag has-background-primary has-text-white px-2 py-1 is-top-right"
              v-if="image.tag"
            >
              {{ image.tag }}
            </strong>
          </div>
          <strong
            class="pt-2 is-title is-size-5 has-custom-skew is-block has-text-grey"
            >{{ image.title }}</strong
          >
          <p v-if="image.optional" class="has-text-grey-light is-size-7">{{ image.optional }}</p>
        </router-link>
      </div>
    </div>
    <div class="container mt-6">
      <div class="has-text-centered mt-6">
        <router-link
          class="button is-medium"
          :to="'/' + $route.params.locale + '/inhalte'"
        >
          {{ $t("views.index.features.cta") }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    images() {
      return [
        {
          title: this.$t("views.index.features.images.techdata.title"),
          class: "has-background-techdata",
        },
        {
          title: this.$t("views.index.features.images.parts.headline"),
          class: "has-background-parts",
        },
        {
          title: this.$t("views.index.features.images.diagrams.title"),
          class: "has-background-diagrams",
          optional: this.$t("views.index.features.images.diagrams.optional"),
        },
        {
          title: this.$t("views.index.features.images.filling.title"),
          class: "has-background-filling",
        },
        {
          title: this.$t("views.index.features.images.torque.title"),
          class: "has-background-torque",
        },
        {
          title: this.$t("views.index.features.images.systemdata.title"),
          class: "has-background-systemdata",
          tag: this.$t("views.index.features.images.systemdata.tag"),
        },
        {
          title: this.$t("views.index.features.images.service.title"),
          class: "has-background-service",
        },
        {
          title: this.$t("views.index.features.images.support.title"),
          class: "has-background-support",
          tag: this.$t("views.index.features.images.support.tag"),
        },
        {
          title: this.$t("views.index.features.images.engine.title"),
          class: "has-background-engine",
        },
        {
          title: this.$t("views.index.features.images.errors.title"),
          class: "has-background-errors",
          optional: this.$t("views.index.features.images.errors.optional"),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.marquee {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(-25%, 0, 0);
  animation: marquee 60s linear infinite;
  a:hover {
    .column-background {
      transition: all 0.2s ease;
      filter: brightness(1.1);
    }
    strong.is-title {
      transition: all 0.2s ease;
      filter: brightness(0.6);
    }
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(-25%, 0, 0);
  }

  100% {
    transform: translate3d(-75%, 0, 0);
  }
}
</style>
