var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"page-voucher"}},[_c('navigation',{attrs:{"fancy":"","utm":_vm.utm}}),_c('section',{staticClass:"hero"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-multiline is-mobile is-vcentered py-6 my-6"},[_c('div',{staticClass:"column column-voodoo is-10-tablet is-offset-1-tablet is-5-desktop is-5-fullhd"},[_c('div',{staticClass:"card is-shadowless has-background-light"},[_c('div',{staticClass:"card-content"},[_c('h2',{staticClass:"title is-marginless is-size-3 is-size-4-mobile"},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.hero.headline"))+" ")])])]),_c('a',{staticClass:"hero-cta is-block py-3 px-5 has-background-primary has-text-white title is-4",attrs:{"href":"#voucherform"}},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.hero.cta"))+" ")])])])])]),_c('a',{staticClass:"hero-scroller has-text-white has-text-centered",attrs:{"href":"#voucherform"}},[_c('fa',{staticClass:"fa-2x",attrs:{"icon":"angle-double-down"}})],1)]),_c('section',{staticClass:"section is-medium",attrs:{"id":"voucherform"}},[_c('div',{staticClass:"container pb-6"},[_c('h2',{staticClass:"title is-size-2 is-size-4-mobile text-dark-600"},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.headline"))+" ")]),_c('p',{staticClass:"mb-5",domProps:{"innerHTML":_vm._s(_vm.$t('views.voucher.form.description'))}})]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column columns is-multiline is-offset-1-tablet is-10-tablet is-offset-2-desktop is-8-desktop is-offset-3-fullhd is-6-fullhd"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"timeline"},[_c('div',{staticClass:"is-relative is-clipped"},[_c('strong',{class:[
                    'timeline-marker',
                    {
                      'is-active':
                        !_vm.loading && !_vm.error && _vm.granit.value === null,
                    } ]},[_vm._v(" 1 ")]),_c('span',{staticClass:"timeline-rule"})]),_c('div',{class:['pb-5 timeline-section', { 'is-disabled': _vm.error }]},[_c('p',{staticClass:"mb-2 mt-1 has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.granit.label"))+" * ")]),_c('div',{staticClass:"field has-addons"},[_c('div',{staticClass:"control has-icons-right is-expanded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.granit.input),expression:"granit.input"}],class:['input formelement'],attrs:{"type":"text","autocomplete":"off","placeholder":_vm.$t('views.voucher.form.granit.label'),"disabled":_vm.error || _vm.loading || _vm.granit.value !== null},domProps:{"value":(_vm.granit.input)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.granit, "input", $event.target.value)},function () {
                          _vm.granit.valid = null;
                          _vm.granit.checked = false;
                          _vm.granit.value = null;
                        }],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.granit.input.trim() && _vm.checkGranit()}}}),_c('span',{staticClass:"icon is-small is-right"},[(_vm.granit.value !== null)?_c('button',{staticClass:"button is-small is-dark",staticStyle:{"pointer-events":"all","border-radius":"2px !important","margin-right":"3px"},attrs:{"disabled":_vm.error || _vm.loading},on:{"click":function($event){return _vm.resetForm()}}},[_c('fa',{attrs:{"icon":"times"}})],1):_vm._e()])]),(_vm.granit.value === null)?_c('p',{staticClass:"control mb-0"},[_c('button',{class:[
                        'button',
                        { 'is-primary': _vm.granit.input.trim().length },
                        { 'is-loading': _vm.granit.loading } ],attrs:{"disabled":_vm.error ||
                        _vm.loading ||
                        !_vm.granit.input.trim().length ||
                        _vm.granit.loading},on:{"click":function($event){return _vm.checkGranit()}}},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.granit.next"))+" ")])]):_vm._e()]),(_vm.granit.checked)?_c('div',[(_vm.granit.valid === true)?_c('div',{staticClass:"has-text-success mt-2"},[_c('strong',[_c('fa',{attrs:{"icon":"check"}}),_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.granit.valid"))+" ")],1)]):_c('div',{staticClass:"has-text-danger mt-2"},[_c('strong',{staticClass:"has-text-danger-dark"},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.granit.invalid-headline"))+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm.granit.valid === null ? _vm.$t("views.voucher.form.granit.invalid-text-unknown") : _vm.$t("views.voucher.form.granit.invalid-text-active"))+" "),_c('br'),_c('br'),_c('i18n',{staticClass:"has-text-danger-dark",attrs:{"path":"views.voucher.form.support"}},[_c('a',{staticClass:"has-text-danger-dark has-text-weight-bold",attrs:{"href":'mailto:' + _vm.selectedPartner.support_contact}},[_vm._v(_vm._s(_vm.selectedPartner.support_contact))])]),_c('div',{staticClass:"is-flex is-justify-content-flex-end mt-2"},[(_vm.granit.valid === null)?_c('router-link',{staticClass:"button is-dark",attrs:{"to":("/" + (_vm.$route.params.locale) + "/register")}},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.granit.cta"))+" ")]):_c('a',{staticClass:"button is-dark",attrs:{"href":("https://rep.guide/" + (_vm.$route.params.locale + _vm.utmString)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.granit.login"))+" "),_c('fa',{staticClass:"fa-fw ml-2",attrs:{"icon":"external-link-alt"}})],1)],1)],1)]):_vm._e()]),_c('div',{staticClass:"is-relative is-clipped"},[_c('strong',{class:[
                    'timeline-marker',
                    {
                      'is-active':
                        !_vm.loading &&
                        !_vm.error &&
                        _vm.granit.value !== null &&
                        _vm.voucher.value === null,
                    } ]},[_vm._v(" 2 ")]),_c('span',{staticClass:"timeline-rule"})]),_c('div',{class:[
                  'pb-5 timeline-section',
                  { 'is-disabled': _vm.error || _vm.granit.value === null } ]},[_c('p',{staticClass:"mb-2 mt-1 has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t("views.register.account.form.promo"))+" * ")]),_c('div',{staticClass:"field has-addons"},[_c('div',{staticClass:"control has-icons-right is-expanded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.voucher.input),expression:"voucher.input"}],class:['input formelement'],attrs:{"type":"text","autocomplete":"off","placeholder":_vm.$t('views.register.account.form.promo'),"disabled":_vm.error ||
                        _vm.loading ||
                        _vm.granit.value === null ||
                        _vm.voucher.value !== null},domProps:{"value":(_vm.voucher.input)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.voucher, "input", $event.target.value)},function () {
                          _vm.voucher.valid = null;
                          _vm.voucher.value = null;
                          _vm.voucher.input = _vm.voucher.input.toUpperCase();
                        }],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.voucher.input.trim() && _vm.checkVoucher()}}}),_c('span',{staticClass:"icon is-small is-right"},[(_vm.voucher.value !== null)?_c('button',{staticClass:"button is-small is-dark",staticStyle:{"pointer-events":"all","border-radius":"2px !important","margin-right":"3px"},attrs:{"disabled":_vm.error || _vm.loading || _vm.voucher.loading},on:{"click":function($event){return _vm.resetVoucher()}}},[_c('fa',{attrs:{"icon":"times"}})],1):_vm._e()])]),(_vm.voucher.value === null)?_c('p',{staticClass:"control mb-0"},[_c('button',{class:[
                        'button',
                        { 'is-primary': _vm.voucher.input.trim().length },
                        { 'is-loading': _vm.voucher.loading } ],attrs:{"disabled":_vm.error ||
                        _vm.loading ||
                        !_vm.voucher.input.trim().length ||
                        _vm.voucher.loading},on:{"click":function($event){return _vm.checkVoucher()}}},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.voucher.check"))+" ")])]):_vm._e()]),(_vm.voucher.valid === false)?_c('div',{staticClass:"has-text-danger mt-2"},[_c('strong',{staticClass:"has-text-danger-dark"},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.voucher.invalid.headline"))+" ")]),_c('br'),(!_vm.voucher.error)?_c('span',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.voucher.invalid.error:generic"))+" ")]):_c('span',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(_vm.$t( ("views.voucher.form.voucher.invalid.error:" + (_vm.voucher.error.message || "generic")) ))+" "),_c('br'),_c('small',{staticClass:"has-text-danger-dark"},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.voucher.invalid.error-code"))+" "),_c('code',{staticClass:"has-text-danger-dark has-text-weight-bold",staticStyle:{"user-select":"all"}},[_vm._v(" "+_vm._s(_vm.voucher.error.code)+" ")])])])]):_vm._e(),(_vm.voucher.valid === true)?_c('div',{staticClass:"has-text-success mt-2"},[_c('strong',{staticClass:"has-text-success-dark"},[_c('fa',{attrs:{"icon":"check"}}),_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.voucher.valid.headline"))+" "),(_vm.voucher.info)?_c('span',[_vm._v(" \""+_vm._s(_vm.$t(("marketing-campaigns." + (_vm.voucher.info.i18n_key))))+"\" ")]):_vm._e()],1),(_vm.voucher.info)?_c('ul',{staticClass:"has-repguide-bullets"},_vm._l((_vm.voucher.info.MarketingOffers),function(offer){return _c('li',{key:offer.id,staticClass:"has-text-primary-dark has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t(("marketing-offers." + (offer.offer_key))))+" ")])}),0):_vm._e(),(_vm.pswVouchers.includes(_vm.voucher.value))?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",staticStyle:{"margin-left":"3.5rem"}},[_c('p',{staticClass:"mb-2 has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t("ag23.signature"))+" ")]),_c('div',{style:({
                          border: '2px dashed !important',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          borderColor:
                            _vm.showValidation && !_vm.signature ? 'red' : '#ddd',
                        }),on:{"click":function($event){return _vm.$refs.signatureModal.open()}}},[_c('div',{staticClass:"is-flex p-2 is-justify-content-center",staticStyle:{"height":"200px"}},[(_vm.signature)?_c('img',{staticStyle:{"max-height":"100%","width":"auto"},attrs:{"src":_vm.signature}}):_vm._e()]),_c('div',{staticClass:"has-text-centered mx-3 py-1 has-text-grey",staticStyle:{"border-top":"1px solid #ddd"}},[_vm._v(" "+_vm._s(_vm.$t("ag23.your-signature"))+" ")])]),_c('p',{staticClass:"mt-5 mb-2 has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t("ag23.complete-name"))+" ")]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:[
                              'input formelement',
                              { 'is-danger': _vm.showValidation && !_vm.name } ],attrs:{"type":"text","autocomplete":"full-name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})])])])]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"is-relative is-clipped"},[_c('strong',{class:[
                    'timeline-marker',
                    {
                      'is-active':
                        !_vm.loading && !_vm.error && _vm.voucher.value !== null,
                    } ]},[_vm._v(" 3 ")]),_c('span',{staticClass:"timeline-rule"})]),_c('div',{class:[
                  'pb-5 mb-2 timeline-section',
                  { 'is-disabled': _vm.error || _vm.voucher.value === null } ]},[_c('p',{staticClass:"mb-2 mt-1 has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.additional.headline"))+" ")]),_c('label',{staticClass:"is-mobile columns"},[_c('div',{staticClass:"column is-narrow"},[_c('input',{staticClass:"checkbox",staticStyle:{"height":"25px","width":"25px"},attrs:{"type":"checkbox","disabled":true},domProps:{"checked":_vm.password}})]),_c('p',{staticClass:"column",staticStyle:{"word-break":"break-word"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.additional.password.label"))+" ")]),_c('br'),_c('small',{staticClass:"has-text-grey"},[_vm._v(" "+_vm._s(_vm.$t( "views.voucher.form.additional.password.description" ))+" ")])])]),_c('label',{staticClass:"is-mobile columns"},[_c('div',{staticClass:"column is-narrow"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.terms),expression:"terms"}],class:[
                        'checkbox',
                        { 'is-danger': _vm.showValidation && !_vm.terms } ],staticStyle:{"height":"25px","width":"25px"},attrs:{"type":"checkbox","disabled":_vm.error ||
                        _vm.loading ||
                        _vm.granit.value === null ||
                        _vm.voucher.value === null},domProps:{"checked":Array.isArray(_vm.terms)?_vm._i(_vm.terms,null)>-1:(_vm.terms)},on:{"change":function($event){var $$a=_vm.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.terms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.terms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.terms=$$c}}}})]),_c('p',{staticClass:"column",staticStyle:{"word-break":"break-word"}},[_c('i18n',{attrs:{"path":"views.voucher.form.additional.terms"}},[_c('a',{attrs:{"href":_vm.generatePartnerLinks().terms,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("views.register.account.form.terms.terms"))+" ")]),_c('span',[_vm._v(_vm._s(_vm.getCompanyName()))]),_c('a',{attrs:{"href":_vm.generatePartnerLinks().pricing,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("views.register.account.form.terms.pricing"))+" ")]),_c('a',{attrs:{"href":_vm.generatePartnerLinks().privacy,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("views.register.account.form.terms.privacy"))+" ")])])],1)])]),_c('div',{staticClass:"is-relative is-clipped"},[_c('strong',{class:[
                    'timeline-marker',
                    { 'is-active': _vm.voucher.value !== null } ]},[(_vm.error)?_c('fa',{attrs:{"icon":"times","size":"xs"}}):_c('fa',{attrs:{"icon":"arrow-right","size":"xs"}})],1)]),_c('div',{class:[
                  'timeline-section',
                  { 'is-disabled': _vm.voucher.value === null } ],staticStyle:{"margin-top":"-0.5rem"}},[(_vm.error)?_c('div',{staticClass:"notification is-danger"},[_c('div',{staticClass:"columns is-mobile is-align-items-center"},[_c('div',{staticClass:"column is-narrow pl-4"},[_c('fa',{staticClass:"fa-2x icon",attrs:{"icon":"exclamation"}})],1),_c('div',{staticClass:"column"},[_c('p',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.error.headline"))+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.error.description"))+" "),_c('br'),_c('a',{staticClass:"mt-2 button is-primary",staticStyle:{"height":"unset !important"},attrs:{"href":_vm.generateMailTo()}},[_c('i18n',{attrs:{"path":"views.voucher.form.error.cta"}},[_c('br',{staticClass:"is-hidden-tablet"})])],1)])])])]):_c('button',{class:[
                    'button is-primary is-fullwidth px-2 is-medium',
                    { 'is-loading': _vm.loading } ],attrs:{"disabled":_vm.error ||
                    _vm.loading ||
                    _vm.granit.value === null ||
                    _vm.voucher.value === null ||
                    (_vm.showValidation && _vm.invalid)},on:{"click":function($event){return _vm.reactivate()}}},[_vm._v(" "+_vm._s(_vm.$t("views.voucher.form.reactivate"))+" ")])])])])])])])]),_c('footerNavigation'),_c('SignatureModal',{ref:"signatureModal",on:{"save":function (newSignature) { return (_vm.signature = newSignature); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }