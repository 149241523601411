<template>
  <div>
    <navigation />
    <section class="section is-medium" id="contact">
      <div class="container">
        <div class="content">
          <h1 class="title is-2 is-size-4-mobile">
            {{ $t("views.contact.headline") }}
          </h1>
          <hr class="is-placeholder is-hidden-mobile" />
          <p>
            {{ $t("views.contact.description") }}
            <a :href="`mailto:${supportContact}`" target="_blank">
              {{ supportContact }}</a
            >.
          </p>
        </div>
      </div>
    </section>
    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import footerNavigation from "../components/footerNavigation.vue";
import { store } from "../store";

export default {
  name: "page-contact",
  components: {
    navigation,
    footerNavigation,
  },
  computed: {
    supportContact() {
      return store.selectedPartner?.support_contact || "repguide@fricke.de";
    },
  },
};
</script>
