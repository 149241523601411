<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="content">
            <i18n path="components.footerNavigation.description" tag="p">
              <strong>Rep.Guide</strong>
              <a
                rel="noopener noreferrer"
                href="https://www.fricke.de"
                target="_blank"
              >
                Fricke Gruppe
              </a>
            </i18n>
          </div>
          <div class="columns">
            <div class="column is-narrow pr-2">
              <a href="https://www.facebook.com/repguide.eu" target="_blank">
                <fa
                  class="has-text-primary is-size-3"
                  :icon="['fab', 'facebook-square']"
                />
              </a>
            </div>
            <div class="column is-narrow px-2">
              <a href="https://linkedin.com/company/rep-guide" target="_blank">
                <fa
                  class="has-text-primary is-size-3"
                  :icon="['fab', 'linkedin']"
                />
              </a>
            </div>
            <div class="column is-narrow px-2">
              <a href="https://www.instagram.com/rep.guide/" target="_blank">
                <fa
                  class="has-text-primary is-size-3"
                  :icon="['fab', 'instagram-square']"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="column">
          <ul class="content">
            <!-- <li>
              <a
                rel="noopener noreferrer"
                href="https://karriere.fricke.de/"
                target="_blank"
              >
                {{ $t("components.footerNavigation.links.carreer") }}
                <fa class="has-text-grey-light ml-1" icon="external-link-alt" />
              </a>
            </li> -->
            <li>
              <router-link :to="'/' + $route.params.locale + '/contact'">
                {{ $t("components.footerNavigation.links.contact") }}
              </router-link>
            </li>
            <li>
              <router-link :to="'/' + $route.params.locale + '/career'">
                {{ $t("components.footerNavigation.links.career") }}
              </router-link>
            </li>
            <li>
              <router-link :to="'/' + $route.params.locale + '/construction'">
                {{ $t("components.footerNavigation.links.construction") }}
              </router-link>
            </li>
            <li>
              <router-link :to="'/' + $route.params.locale + '/education'">
                {{ $t("components.footerNavigation.links.education") }}
              </router-link>
            </li>
            <li>
              <router-link :to="'/' + $route.params.locale + '/terms'">
                {{ $t("components.footerNavigation.links.terms") }}
              </router-link>
            </li>
            <li>
              <router-link :to="'/' + $route.params.locale + '/pricing'">
                {{ $t("components.footerNavigation.links.pricing") }}
              </router-link>
            </li>
            <li>
              <router-link :to="'/' + $route.params.locale + '/privacy'">
                {{ $t("components.footerNavigation.links.privacy") }}
              </router-link>
            </li>
            <li>
              <router-link :to="'/' + $route.params.locale + '/imprint'">
                {{ $t("components.footerNavigation.links.imprint") }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="column">
          <div class="content">
            &copy; {{ new Date().getFullYear() }}<br />
            <address v-if="selectedPartner">
              <strong>{{ selectedPartner.company }}</strong>
              <br />
              {{ selectedPartner.address.address_line_1 }}<br />
              <template v-if="selectedPartner.address.address_line_2">
                {{ selectedPartner.address.address_line_2 }}
                <br />
              </template>
              {{ selectedPartner.AppCountry.country_code
              }}<span
                v-if="
                  selectedPartner.AppCountry.country_code &&
                  selectedPartner.address.zip_code
                "
                >-</span
              >{{ selectedPartner.address.zip_code }}
              {{ selectedPartner.address.city_name }}
            </address>
            <address v-else>
              <strong>Wilhelm Fricke SE</strong><br />
              Am Kreuzkamp 7<br />
              DE-27404 Heeslingen
            </address>
          </div>
        </div>
      </div>
      <div class="content has-text-centered" @click="new Error('testSentry')">
        <hr class="placeholder" />
        <i18n path="components.footerNavigation.links.dedication">
          <fa class="has-text-primary" icon="heart" />
          <fa class="has-text-primary" icon="coffee" />
        </i18n>
      </div>
    </div>
  </footer>
</template>

<script>
import { store } from "../store";

export default {
  computed: {
    selectedPartner() {
      return store.selectedPartner;
    },
  },
};
</script>
