<template>
  <div id="page-service">
    <navigation fancy :utm="utm" />

    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-multiline is-mobile is-vcentered py-6 my-6">
            <div
              class="column column-voodoo is-10-tablet is-offset-1-tablet is-5-desktop is-5-fullhd"
            >
              <div
                style="max-width: 600px; margin: 0 auto"
                class="is-hidden-desktop"
              >
                <figure class="image mb-6 is-5by3">
                  <img
                    :alt="$t('views.service.hero.mood-alt')"
                    src="../assets/repguide-screenshot-wartung.png"
                  />
                </figure>
              </div>

              <div class="card is-shadowless has-background-light">
                <div class="card-content">
                  <h2 class="title is-marginless is-size-3 is-size-4-mobile">
                    {{ $t("views.service.hero.headline") }}
                  </h2>
                </div>
              </div>
              <router-link
                :to="'/' + $route.params.locale + '/register' + utmString"
                class="hero-cta is-block py-3 px-5 has-background-primary has-text-white title is-4"
                >{{ $t("views.service.hero.cta") }}</router-link
              >
            </div>
            <div class="column is-offset-1 is-hidden-touch">
              <div>
                <figure class="image is-5by3">
                  <img
                    :alt="$t('views.service.hero.mood-alt')"
                    src="../assets/repguide-screenshot-wartung.png"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        href="#savetime"
        class="hero-scroller has-text-white has-text-centered"
      >
        <fa icon="angle-double-down" class="fa-2x" />
      </a>
    </section>

    <section class="section is-medium" id="savetime">
      <div class="container">
        <h2 class="title is-size-2 is-size-4-mobile">
          {{ $t("views.service.time.headline") }}
        </h2>
        <p class="mb-6">
          <i18n path="views.service.time.description.text">
            <strong>{{ $t("views.service.time.description.strong") }}</strong>
          </i18n>
        </p>
      </div>
      <div class="container">
        <div
          class="columns is-mobile is-multiline is-variable is-1-mobile is-2-tablet is-3-desktop is-4-widescreen"
        >
          <div
            class="column is-half-mobile is-half-tablet is-one-quarter-desktop"
          >
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img
                    src="../assets/wartung/repguide-wartung-diagnose.jpg"
                    :alt="$t('views.service.time.cards.digital.mood-alt')"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <h2>{{ $t("views.service.time.cards.digital.headline") }}</h2>
                  <i18n
                    path="views.service.time.cards.digital.description.text"
                    tag="p"
                  >
                    <i18n
                      path="views.service.time.cards.digital.description.intervals"
                      tag="strong"
                    >
                      <fa icon="clock" class="icon icon-text" />
                    </i18n>
                    <i18n
                      path="views.service.time.cards.digital.description.print"
                      tag="strong"
                    >
                      <fa icon="print" class="icon icon-text" />
                    </i18n>
                  </i18n>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column is-half-mobile is-half-tablet is-one-quarter-desktop"
          >
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img
                    src="../assets/wartung/repguide-wartung-filter.jpg"
                    :alt="$t('views.service.time.cards.filter.mood-alt')"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <h2>{{ $t("views.service.time.cards.filter.headline") }}</h2>
                  <i18n
                    path="views.service.time.cards.filter.description.text"
                    tag="p"
                  >
                    <i18n
                      path="views.service.time.cards.filter.description.filter"
                      tag="strong"
                    >
                      <fa icon="database" class="icon icon-text" />
                    </i18n>
                  </i18n>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column is-half-mobile is-half-tablet is-one-quarter-desktop"
          >
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img
                    src="../assets/wartung/repguide-wartung-oel.jpg"
                    :alt="$t('views.service.time.cards.oil.mood-alt')"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <h2>{{ $t("views.service.time.cards.oil.headline") }}</h2>
                  <i18n
                    path="views.service.time.cards.oil.description.text"
                    tag="p"
                  >
                    <i18n
                      path="views.service.time.cards.oil.description.lubricants"
                      tag="strong"
                    >
                      <fa icon="oil-can" class="icon icon-text" />
                    </i18n>
                  </i18n>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column is-half-mobile is-half-tablet is-one-quarter-desktop"
          >
            <div class="card">
              <div class="card-image">
                <figure class="image">
                  <img
                    src="../assets/wartung/repguide-wartung-teile.jpg"
                    :alt="$t('views.service.time.cards.parts.mood-alt')"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <h2>
                    {{ $t("views.service.time.cards.parts.headline") }}
                    <sup class="tag is-size-7 is-grey is-pulled-right">
                      {{ $t("views.service.time.cards.parts.soon") }}
                    </sup>
                  </h2>
                  <i18n
                    path="views.service.time.cards.parts.description.text"
                    tag="p"
                  >
                    <strong>{{
                      $t("views.service.time.cards.parts.description.lists")
                    }}</strong>
                    <strong>{{
                      $t("views.service.time.cards.parts.description.kits")
                    }}</strong>
                  </i18n>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="has-text-centered pt-6">
        <router-link
          :to="'/' + $route.params.locale + '/register' + utmString"
          class="button is-primary is-large"
        >
          {{ $t("views.service.time.cta") }}
        </router-link>
      </div>
    </section>

    <section class="section is-medium has-background-light" id="screenshots">
      <div class="container">
        <h2 class="title is-size-2 is-size-4-mobile text-dark-600">
          {{ $t("views.service.explanation.headline") }}
        </h2>
        <p class="mb-6">
          <i18n path="views.service.explanation.description.text">
            <strong>{{
              $t("views.service.explanation.description.insight")
            }}</strong>
            <strong>John Deere 6010</strong>
            <strong>{{
              $t("views.service.explanation.description.toggle")
            }}</strong>
            <strong>{{
              $t("views.service.explanation.description.config")
            }}</strong>
            <strong>{{
              $t("views.service.explanation.description.print")
            }}</strong>
          </i18n>
        </p>
      </div>
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <p>
                    <strong class="tag is-primary is-medium is-rounded">
                      1
                    </strong>
                  </p>
                  <i18n path="views.service.explanation.step-1.text" tag="p">
                    <strong>{{
                      $t("views.service.explanation.step-1.strong")
                    }}</strong>
                  </i18n>
                </div>
              </div>
              <div class="card-image">
                <figure class="image">
                  <img
                    src="../assets/wartung/repguide-wartung-screenshots-intervalle.jpg"
                    :alt="$t('views.service.explanation.step-1.mood-alt')"
                    class="p-2"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <p>
                    <strong class="tag is-primary is-medium is-rounded">
                      2
                    </strong>
                  </p>
                  <i18n path="views.service.explanation.step-2.text" tag="p">
                    <strong>{{
                      $t("views.service.explanation.step-2.strong")
                    }}</strong>
                  </i18n>
                </div>
              </div>
              <div class="card-image">
                <figure class="image">
                  <img
                    src="../assets/wartung/repguide-wartung-screenshots-checks.jpg"
                    :alt="$t('views.service.explanation.step-2.mood-alt')"
                    class="p-2"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <p>
                    <strong class="tag is-primary is-medium is-rounded">
                      3
                    </strong>
                  </p>
                  <i18n path="views.service.explanation.step-3.text" tag="p">
                    <strong>{{
                      $t("views.service.explanation.step-3.strong")
                    }}</strong>
                  </i18n>
                </div>
              </div>
              <div class="card-image">
                <figure class="image">
                  <img
                    src="../assets/wartung/repguide-wartung-screenshots-config.jpg"
                    :alt="$t('views.service.explanation.step-3.mood-alt')"
                    class="p-2"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <p>
                    <strong class="tag is-primary is-medium is-rounded">
                      4
                    </strong>
                  </p>
                  <i18n path="views.service.explanation.step-4.text" tag="p">
                    <strong>{{
                      $t("views.service.explanation.step-4.strong")
                    }}</strong>
                  </i18n>
                </div>
              </div>
              <div class="card-image">
                <figure class="image">
                  <img
                    src="../assets/wartung/repguide-wartung-screenshots-print.jpg"
                    :alt="$t('views.service.explanation.step-4.mood-alt')"
                    class="p-2"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="has-text-centered pt-6 mt-6">
        <router-link
          :to="'/' + $route.params.locale + '/register' + utmString"
          class="button is-primary is-large"
        >
          {{ $t("views.service.time.cta") }}
        </router-link>
      </div>
    </section>

    <quote />

    <footerNavigation />
  </div>
</template>

<script>
import navigation from "../components/navigation.vue";
import quote from "../components/sections/quote_new.vue";
import footerNavigation from "../components/footerNavigation.vue";
import { generateSearchParams } from "../utm.js";

export default {
  name: "service",
  props: {
    utm: { type: Object, default: () => {} },
  },
  computed: {
    utmString() {
      const UTM = { ...this.utm, utm_content: "landingpage_service" };
      return generateSearchParams(UTM);
    },
  },
  components: {
    navigation,
    quote,
    footerNavigation,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#page-service .hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../assets/repguide-background-service.jpg") no-repeat center center
      fixed;
}

@media screen and (max-width: 768px) {
  #savetime.section,
  #screenshots.section {
    padding: 1.5rem 0.5rem;
  }
  #savetime .card .card-content {
    padding: 1rem;
  }
}
</style>
