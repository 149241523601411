<template>
  <section class="section is-medium has-background-grey" id="benefits">
    <div class="container">
      <div class="columns is-desktop is-vcentered">
        <div class="column">
          <figure class="image">
            <img
              src="../../assets/flying-features.png"
              :alt="$t('views.index.benefits.mood-alt')"
            />
          </figure>
        </div>
        <div class="column">
          <h2 class="no-margin-top title is-2 is-size-4-mobile has-text-white">
            {{ $t("views.index.benefits.headline") }}
          </h2>
          <div
            class="media has-text-light is-size-5-desktop is-size-6-touch is-borderless p-0"
            v-for="benefit in benefits"
            :key="benefit.title"
          >
            <figure class="media-left pt-1">
              <fa-layers class="fa-3x fa-fw">
                <fa class="has-text-grey-darker" icon="square" />
                <fa
                  :icon="benefit.icon"
                  class="has-text-primary"
                  transform="shrink-9"
                />
              </fa-layers>
            </figure>
            <div class="media-content">
              <div class="content pt-2">
                <strong class="has-text-white">{{ benefit.title }}:</strong>
                {{ benefit.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    benefits() {
      return [
        {
          icon: "stopwatch",
          title: this.$t("views.index.benefits.bullets.time.title"),
          desc: this.$t("views.index.benefits.bullets.time.description"),
        },
        {
          icon: "laptop",
          title: this.$t("views.index.benefits.bullets.digital.title"),
          desc: this.$t("views.index.benefits.bullets.digital.description"),
        },
        {
          icon: "mobile",
          title: this.$t("views.index.benefits.bullets.hardware.title"),
          desc: this.$t("views.index.benefits.bullets.hardware.description"),
        },
        {
          icon: "tractor",
          title: this.$t("views.index.benefits.bullets.database.title"),
          desc: this.$t("views.index.benefits.bullets.database.description"),
        },
        {
          icon: "exclamation",
          title: this.$t("views.index.benefits.bullets.risk.title"),
          desc: this.$t("views.index.benefits.bullets.risk.description"),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.is-borderless {
  border: none !important;
}
</style>
