import { render, staticRenderFns } from "./returned.vue?vue&type=template&id=67f1d7e9&"
import script from "./returned.vue?vue&type=script&lang=js&"
export * from "./returned.vue?vue&type=script&lang=js&"
import style0 from "./returned.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports